import { ref } from "vue";
import api from '@/services/auth'
import { useSweetalertTopEnd } from '@/js/sweetalert'

export function useCompany(){
    const company = ref(
        {
            id : "",
            name_th : "",
            tax_no : "",
            address : "",
            phone : "",
        }
    );
    const loading_company = ref(false);

    const getCompanyById = async (id) => { 
        await api.get(`api/companies/${id}`).then((response)=>{
            let success_response = response.data;
            company.value = success_response.data
            loading_company.value = true
            
        }).catch( error => {
            let error_response = error.response.data;
            useSweetalertTopEnd(1500, 'error', error_response.message)
        });
    }
    return { company, getCompanyById, loading_company };
}