<template>
    <div class="flex flex-col w-full bg-white rounded-2xl gap-4">
        <div class="flex items-center justify-between px-5 pt-3">
            <div
                class="text-md font-semibold text-gray-700 md:text-xl dark:text-gray-200"
            >
                Payment
            </div>
        </div>
        <div class="flex flex-wrap">
            <div class="w-full px-3">
                <span
                    v-for="payment_type_data in payment_type_lists"
                    :key="payment_type_data.id"
                     class="inline-block"
                >
                    <input
                        v-model="form.payment_type"
                        @change="select_payment_type"
                        type="radio"
                        :value="payment_type_data.message"
                        :id="payment_type_data.label"
                        class="cursor-pointer mr-1"
                    />
                    <label
                        :for="payment_type_data.label"
                        class="tracking-wide text-gray-700 text-xs font-bold cursor-pointer mr-3"
                        >{{ payment_type_data.message }}</label
                    >
                </span>
            </div>
        </div>
        <div
            v-if="
                form.payment_type != null &&
                    payment_type_lists.find(
                        (item) => item.message == form.payment_type
                    ).option.length > 0
            "
            class="flex flex-wrap my-1"
        >
            <div class="w-full px-3">
                <label
                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="payment_note"
                >
                    {{
                        payment_type_lists.find(
                            (item) => item.message == form.payment_type
                        ).topic_option
                    }}
                </label>
                <span
                    v-for="option in payment_type_lists.find(
                        (item) => item.message == form.payment_type
                    ).option"
                    :key="option.label"
                >
                    <input
                        v-model="form.payment_option"
                        type="radio"
                        :value="option.message"
                        :id="option.label"
                        class="cursor-pointer mr-1"
                    />
                    <label
                        :for="option.label"
                        class="tracking-wide text-gray-700 text-xs font-bold cursor-pointer mr-3"
                        >{{ option.message }}</label
                    >
                </span>
            </div>
        </div>
        <div
            v-if="
                payment_type_lists.find((item) => item.message == form.payment_type)
                    ? payment_type_lists.find(
                          (item) => item.message == form.payment_type
                      ).is_order_no
                    : false
            "
            class="flex flex-wrap my-1"
        >
            <div class="w-full px-3">
                <label
                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="online_order_no"
                >
                    Order no <span class="text-red-600">*</span>
                </label>
                <input
                    v-model="form.online_order_no"
                    type="text"
                    name="online_order_no"
                    id="online_order_no"
                    cols="20"
                    rows="5"
                    class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-green-400"
                />
            </div>
        </div>
        <div class="flex flex-wrap my-1">
            <div class="w-full px-3">
                <label
                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="payment_note"
                >
                    Note
                </label>
                <textarea
                    v-model="form.payment_note"
                    name="payment_note"
                    id="payment_note"
                    cols="20"
                    rows="8"
                    class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-green-400"
                ></textarea>
            </div>
        </div>
    </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { useFormatNumber } from "@/js/format";

export default {
    name: "Payment",
    setup() {

        const store = useStore();
        function select_payment_type(){
            this.form.online_order_no=""
            if(this.payment_type_lists.find(item=>item.message==this.form.payment_type).option.length > 0){
                this.form.payment_option = ""
            }else{
                this.form.payment_option = this.form.payment_type
            }
        }
        return {
            payment_type_lists: computed(() => store.state.payment.payment_type_lists),
            form: computed(() => store.state.manualOrder.form),
            useFormatNumber,
            select_payment_type
        };
    },
};
</script>
