<template>
    <div class="flex flex-col w-full px-5 mx-2 bg-white rounded-2xl pb-2">
        <div class="flex items-center justify-between mt-5 mb-2">
            <h2 class="my-1 text-sm font-semibold text-gray-700 md:text-xl dark:text-gray-200">
                Setting
                
            </h2>
        </div>
        <div class="grid">
            <div class="grid grid-cols-6">
                <div class="px-4 py-2 font-semibold">Branch</div>
                <div class="px-4 py-2"><input v-model="warehouse_location" type="text" class="py-1 px-4 bg-gray-200 text-gray-800 border-gray-300 border-2 outline-none placeholder-gray-500" readonly></div>
            </div>
            <div class="grid grid-cols-6">
                <div class="px-4 py-2 font-semibold">POS ID</div>
                <div class="px-4 py-2"><input v-model="pos_id" type="text" class="py-1 px-4 bg-gray-200 text-gray-800 border-gray-300 border-2 outline-none placeholder-gray-500" readonly></div>
            </div>
            <div class="grid grid-cols-6">
                <div class="px-4 py-2 font-semibold">POS NO.</div>
                <div class="px-4 py-2"><input v-model="pos_no" type="text" class="py-1 px-4 bg-gray-200 text-gray-800 border-gray-300 border-2 outline-none placeholder-gray-500" readonly></div>
            </div>
            <div class="grid grid-cols-6">
                <div class="px-4 py-2 font-semibold">Branch Key</div>
                <div class="px-4 py-2">
                    <input v-model="branch_key_show" type="text" class="py-1 px-4 bg-gray-200 text-gray-800 border-gray-300 border-2 outline-none placeholder-gray-500" readonly>
                    <button v-if="role=='admin'" @click="modal_branch_key = true"  class="items-center justify-between px-4 py-2 mt-2 text-sm font-medium leading-5 text-white transition-colors duration-150 border border-transparent rounded-lg focus:outline-none bg-yellow-500 hover:bg-yellow-700" >
                        <span class="mr-2 font-bold">+</span>
                        <span>Edit</span>
                    </button>
                </div>
            </div>
            
        </div>
    </div>
    <div v-if="modal_branch_key" class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
        <div @click="modal_branch_key = false" class="modal-overlay absolute w-full h-full backdrop-filter backdrop-blur-sm backdrop-brightness-50"></div>
        
        <div class="modal-container bg-white w-1/2 mx-auto rounded shadow-lg z-50 overflow-y-auto">

            <!-- Add margin if you want to see some of the overlay behind the modal-->
            <div class="modal-content py-4 text-left px-6">
            <!--Title-->
            <div class="flex justify-between items-center pb-3">
                <p class="font-bold" v-if="!branch_key_show">Create Branch Key</p>
                <p class="font-bold" v-if="branch_key_show">Update Branch Key</p>
                <div @click="modal_branch_key = false" class="modal-close cursor-pointer z-50">
                    <em class="fas fa-times"></em>
                </div>
            </div>
            <hr class="mb-2">
            <!--Body-->
            <div>
                <form @submit.prevent="onSubmit" class="w-full pt-5">
                    <div class="flex flex-wrap  mb-3">
                        <div class="w-full px-3">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="input_branch_key">
                                Branch Key <span class="text-red-500">*</span>
                            </label>
                            <input v-model="input_branch_key" class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-green-400" id="input_branch_key" type="text" placeholder="Branch Key" required>
                        </div>
                    </div>
                    
                    <!--Footer-->
                    <div class="flex justify-end pt-2">
                        <button @click="modal_branch_key = false" type="button" class="p-3 rounded-lg bg-red-400 hover:bg-red-600 text-white font-bold mr-2 focus:outline-none">Cancel</button>
                        <button type="submit" class="p-3 rounded-lg bg-green-400 hover:bg-green-600 text-white font-bold mr-2 focus:outline-none">Save</button>
                    </div>
                </form>
            </div>
            
            </div>
        </div>
    </div>
    
</template>

<script>
import { ref } from "vue";

export default {
    name:"Setting",
    setup(){
        const branch_key = localStorage.getItem('branch_key')
        const branch_key_show = ref(branch_key)
        const input_branch_key = ref(branch_key)
        const modal_branch_key = ref(false)
        const username = localStorage.getItem('username')
        const pos_id = localStorage.getItem('pos_id')
        const pos_no = localStorage.getItem('pos_no')
        const warehouse_location = localStorage.getItem('warehouse_location')
        const role = localStorage.getItem('role')

        const onSubmit = () => { 
            branch_key_show.value = input_branch_key.value
            localStorage.setItem('branch_key', input_branch_key.value)
            modal_branch_key.value = false
        }
        return { branch_key_show, input_branch_key, modal_branch_key, onSubmit, username, pos_id, pos_no, warehouse_location, role }
    }
}
</script>