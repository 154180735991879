<template>
  <div v-if="isLoading" class="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50">
      <span class="text-green-500 opacity-100 top-1/2 my-0 mx-auto block relative w-0 h-0" >
        <i class="fas fa-circle-notch fa-spin fa-5x"></i>
      </span>
  </div>
  <router-view/>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'App',
    watch: {
      $route(to){
        document.title = to.meta.title
      }
    },
    computed: {
      ...mapState(['isLoading']),
    }
}
</script>
<style>

</style>
<style src="@vueform/multiselect/themes/default.css"></style>