import { createRouter, createWebHistory } from 'vue-router'
import Main from '@/layouts/Main.vue'
import Login from '@/views/Login.vue'
import Pos from '@/views/Pos.vue'
import Order from '@/views/Order.vue'
import Invoice from '@/views/invoice/Invoice.vue'
import InvoicePdf from '@/views/invoice/Pdf.vue'
import OrderReceipt from '@/views/order/Receipt.vue'
import Receipt from '@/views/receipt/Receipt.vue'
import ReceiptPdf from '@/views/receipt/Pdf.vue'
import Customer from '@/views/Customer.vue'
import Setting from '@/views/Setting.vue'
import manualOrderIndex from '@/views/manual-order/Index.vue'
import manualOrderCreate from '@/views/manual-order/Create.vue'
import manualOrderReceipt from '@/views/manual-order/Receipt.vue'



function authGuard(to, from, next){
  const pos_warehouse_location_id = localStorage.getItem('pos_warehouse_location_id');
  let isAuthenticated = false

  if(localStorage.getItem('user')){

    var time_current = new Date();
    var userStorage = localStorage.getItem('user')
    let userStorageJSON = JSON.parse(userStorage)
    let time_expires_in = new Date(userStorageJSON['expires_in'])
    if(time_current >= time_expires_in){
      isAuthenticated = false 
    }else{
      isAuthenticated = true 
    }
  }else{
    isAuthenticated = false 
  }

  if(isAuthenticated){
    if(to.name=="receipts" && pos_warehouse_location_id!=1){
      next({name: from.name})
    }
    next()
  }else{
    next({name: 'Login'})
  }

}

const routes = [
  {
    path: '/',
    component: Main,
    children: [
      {
        path: '',
        name: 'Pos',
        component: Pos,
        beforeEnter: authGuard
      }
    ],
    meta: { title: 'POS' }
  },
  {
    path: '/orders',
    component: Main,
    children: [
      {
        path: '',
        name: 'Order',
        component: Order,
        beforeEnter: authGuard
      }
    ],
    meta: { title: 'Order' }
  },
  {
    path: '/orders/:order_no',
    name: 'OrderReceipt',
    component: OrderReceipt,
    beforeEnter: authGuard,
    meta: { title: 'Order Receipt' }
  },
  {
    path: '/invoices',
    component: Main,
    children: [
      {
        path: '',
        name: 'invoice',
        component: Invoice,
        beforeEnter: authGuard
      }
    ],
    meta: { title: 'Invoice' }
  },
  {
    path: '/invoices/:tax_invoice_no',
    name: 'InvoicePdf',
    component: InvoicePdf,
    beforeEnter: authGuard,
    meta: { title: 'Tax Invoice' }
  },
  {
    path: '/receipts',
    component: Main,
    children: [
      {
        path: '',
        name: 'receipts',
        component: Receipt,
        beforeEnter: authGuard
      }
    ],
    meta: { title: 'Receipt' }
  },
  {
    path: '/receipts/:receipt_no',
    name: 'ReceiptPdf',
    component: ReceiptPdf,
    beforeEnter: authGuard,
    meta: { title: 'Receipt' }
  },
  {
    path: '/customers',
    component: Main,
    children: [
      {
        path: '',
        name: 'Customer',
        component: Customer,
        beforeEnter: authGuard
      },

    ],
    meta: { title: 'Customer' }
  },
  {
    path: '/setting',
    component: Main,
    children: [
      {
        path: '',
        name: 'Setting',
        component: Setting,
        beforeEnter: authGuard
      },

    ],
    meta: { title: 'Setting' }
  },
  {
    path: '/manual-order',
    component: Main,
    children: [
      {
        path: '',
        name: 'Index',
        component: manualOrderIndex,
        beforeEnter: authGuard
      },
      {
        path: 'create',
        name: 'Create',
        component: manualOrderCreate,
        beforeEnter: authGuard
      },

    ],
    meta: { title: 'Order' }
  },
  {
    path: '/manual-order/:manual_order_id',
    name: 'manualOrderReceipt',
    component: manualOrderReceipt,
    beforeEnter: authGuard,
    meta: { title: 'Order Receipt' }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { title: 'Login' }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
