<template>

    <div v-if="modal_form_tax_props && loading" class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center z-10">
      <div @click="closeModalTax" class="modal-overlay absolute w-full h-full backdrop-filter backdrop-blur-sm backdrop-brightness-50"></div>
      
      <div class="modal-container bg-white w-1/2 mx-auto rounded shadow-lg z-50 overflow-y-auto">

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="modal-content py-4 text-left px-6">
          <!--Title-->
          <div class="flex justify-between items-center pb-3">
            <p class="font-bold">Order : {{ order_no_props }}</p>
            <div @click="closeModalTax" class="modal-close cursor-pointer z-50">
              <em class="fas fa-times"></em>
            </div>
          </div>
          <hr class="mb-2">
          <!--Body-->
          <div>
            <form @submit.prevent="onSubmitTax" class="w-full pt-5">
                <div class="flex flex-wrap  mb-3">
                    <div class="w-full px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="tax_invoice_date">
                        Tax invoice2 Date <span class="text-red-500">*</span>
                    </label>
                    <input v-model="form.tax_invoice_date" :readonly="form.tax_invoice_no!=''" type="date" @keydown="dontKey" :class="form.tax_invoice_no!='' ? 'bg-gray-100' : ''" class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-green-400" id="tax_invoice_date" required>
                    <p class="text-red-500 text-xs italic">{{ error_msg.tax_invoice_date }}</p>
                    </div>
                </div>
                <div class="flex flex-wrap mb-3">
                    <div class="w-full px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="company_id">
                        Company <span class="text-red-500">*</span>
                    </label>
                    
                        <Multiselect
                            v-model="company_id"
                            :options="company_options"
                            searchable
                            disabled
                            placeholder="Select option"
                        />
                    <p class="text-red-500 text-xs italic">{{ error_msg.company_id }}</p>
                    </div>
                </div>
                <div class="flex flex-wrap mb-3">
                    <div class="w-full px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="customer_id">
                        Customer <span class="text-red-500">*</span>
                    </label>
                    
                        <Multiselect
                            v-model="customer_id"
                            :options="customer_options"
                            searchable
                            required
                            placeholder="Select option"
                        />
                    <p class="text-red-500 text-xs italic">{{ error_msg.customer_id }}</p>
                    </div>
                </div>
                <div class="flex flex-wrap  mb-3">
                    <div class="w-full px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="customer_site">
                        Customer site
                    </label>
                    <input v-model="form.customer_site" class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-green-400" id="customer_site" type="text" placeholder="Customer Site">
                    <p class="text-red-500 text-xs italic">{{ error_msg.customer_site }}</p>
                    </div>
                </div>
                <div class="flex flex-wrap  mb-3">
                    <div class="w-full px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="Note">
                        Note
                    </label>
                    <textarea v-model="form.note" name="note" id="note" cols="20" rows="5" class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-green-400"></textarea>
                    <p class="text-red-500 text-xs italic">{{ error_msg.note }}</p>
                    </div>
                </div>
                
                <!--Footer-->
                <div class="flex justify-end pt-2">
                  <button @click="clearForm" type="button" class="p-3 rounded-lg bg-red-400 hover:bg-red-600 text-white font-bold mr-2">Clear</button>
                  <button type="submit" class="p-3 rounded-lg bg-green-400 hover:bg-green-600 text-white font-bold mr-2">Save</button>
                </div>
            </form>
          </div>
          
        </div>
      </div>
    </div>
</template>

<script>
import { ref, reactive, onMounted } from "vue"
import api from '@/services/auth'
import { useSweetalertTopEnd, useSweetalertError } from '@/js/sweetalert'
import Multiselect from '@vueform/multiselect'
import { useGetOrderByOrderNo } from '@/js/pos_order'
import moment from 'moment'

export default {
  name: 'TaxModal',
  props:['modal_form_tax_props', 'order_no_props'],
  emits: ['closeModalTax','saveTax'],
  components: {
    Multiselect,
  },
  setup(props,{emit}){
    const { order, getOrderByOrderNo, loading } = useGetOrderByOrderNo();
    const customer_id = ref()
    const customer_options = ref([])
    const company_id = ref(1)
    const company_options = ref([])
    const formatter = ref({
      date: 'DD/MM/YYYY',
      month: 'MMM'
    })
    const form = reactive({
      tax_invoice_no : '',
      customer_site : 'สำนักงานใหญ่',
      tax_invoice_date : '',
      note : '',
    })
    const error_msg = reactive({
      customer_site : '',
      tax_invoice_date : '',
      note : '',
    })

    const getCustomers = () => { 
        api.get(`api/customers?page_size=All`).then((response)=>{
            let success_response = response.data;
            success_response.data.forEach(function(item){
            let arr = {
                value : item.id,
                label : `[${item.tax_no}] ${item.phone} - ${item.full_name}`
            };
            customer_options.value.push(arr)
            })

        }).catch( error => {
            let error_response = error.response.data;
            useSweetalertTopEnd(1500, 'error', error_response.message)
        });
    }
    const getCompanies = () => { 
        api.get(`api/companies`).then((response)=>{
            let success_response = response.data;
            success_response.data.forEach(function(item){
            let arr = {
                value : item.id,
                label : item.initial
            };
            company_options.value.push(arr)
            })

        }).catch( error => {
            let error_response = error.response.data;
            useSweetalertTopEnd(1500, 'error', error_response.message)
        });
    }

    onMounted(() => {
        getCustomers();
        getCompanies();
        getOrderByOrderNo(props['order_no_props']).then(()=>{
          if(order.value.pos_order_invoice){
            form.tax_invoice_no = order.value.pos_order_invoice.tax_invoice_no
            form.customer_site = order.value.pos_order_invoice.customer_site
            form.tax_invoice_date = moment(String(order.value.pos_order_invoice.tax_invoice_date)).format('YYYY-MM-DD')
            form.note = order.value.pos_order_invoice.note
            company_id.value = order.value.pos_order_invoice.company_id
            customer_id.value = order.value.pos_order_invoice.pos_customer_id
          }
        })
        
    });
    const onSubmitTax = () => { 
      api.put(`api/pos_orders/${props['order_no_props']}/tax_invoice`,{
          tax_invoice_no : form.tax_invoice_no,
          tax_invoice_date : form.tax_invoice_date,
          company_id : company_id.value,
          customer_id : customer_id.value,
          customer_site : form.customer_site,
          note : form.note
      }).then((response)=>{
          closeModalTax()
          clearForm()
          // console.log(response.data)
          let success_response = response.data;
          useSweetalertTopEnd(1500, 'success', success_response.message)

          emit('saveTax',response.data)

      }).catch( error => {
          clearError()
          let error_response = error.response.data;
          useSweetalertError("Error from order management "+error_response.message)
          if(error_response.status == 422){
            for (const key in error_response.errors) {
                error_msg[key] = error_response.errors[key][0]
                // console.log(`${key}: ${error_response.errors[key]}`);
            }
          }
      });
    }
    const closeModalTax = () => {
        clearForm()
        emit('closeModalTax', "")
    }
    const clearForm = () => {
      customer_id.value = ''
      company_id.value = ''
      form.customer_site = 'สำนักงานใหญ่'
      form.tax_invoice_date = 'สำนักงานใหญ่'
      form.note = ''
      clearError()
    }
    const clearError = () => {
      error_msg.customer_site = ''
      error_msg.tax_invoice_date = ''
      error_msg.note = ''
    }
    function dontKey(event){
      event.preventDefault();
      return false;
    }
    return { order, loading, company_id, company_options, customer_id, customer_options, formatter, form, error_msg, onSubmitTax, clearForm, clearError, closeModalTax, getCompanies, dontKey }
  }
}
</script>
<style>
</style>