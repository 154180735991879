<template>
    <div class="flex items-center min-h-screen p-6 pt-0 bg-gray-100  dark:bg-gray-900">
      <div class="flex-1 h-full max-w-4xl mx-auto bg-white rounded-lg shadow-xl dark:bg-gray-800">
        <div class="flex flex-col md:flex-row">
          <div class="h-32 md:h-auto md:w-1/2">
            <img
              aria-hidden="true"
              class="object-cover w-full h-full dark:hidden"
              src="https://images.unsplash.com/photo-1556742044-3c52d6e88c62?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.9&auto=format&fit=crop&w=1470&q=80"
              alt="Office"/>
          </div>

          <div class="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
            
            <div class="w-full">

              <h1 class="mb-4 text-xl font-bold text-gray-700 dark:text-gray-200">Sign in to <span class="text-red-600">The Master for PhotoFair 2022</span></h1>

              <form @submit.prevent="onSubmit">

                <!-- <label class="block mt-3 mb-2 font-bold text-gray-700" for="pos_id">POS ID</label>
                <Multiselect
                    v-model="form.pos_machine_id"
                    :options="pos_machine_options"
                    searchable
                    required
                    /> -->
                
                <label class="block mt-3 mb-2 font-bold text-gray-700" for="username">Username</label>
                <input v-model="form.username" class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none" type="text" required>
           

                <label class="block mt-3 mb-2 font-bold text-gray-700" for="password">Password</label>
                <input v-model="form.password" class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none" type="password" required>
                
                <!-- <label class="block mt-3 mb-2 font-bold text-gray-700" for="warehouse_location_id">Warehouse</label>
                <Multiselect
                    v-model="warehouse_location_id"
                    :options="warehouse_location_options"
                    searchable
                    required
                    /> -->
                <button type="submit" class="block w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-green-500 border border-transparent rounded-lg cursor-pointer active:bg-green-600 hover:bg-green-700">Login</button>
              </form>

              <div class="text-sm text-right">
                Backend {{ backend_url }}<br>
                  Version {{ version }}
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue' 
import axios from "axios";
import Swal from 'sweetalert2';
import { useRouter } from "vue-router";
// import Multiselect from '@vueform/multiselect'
import api from '@/services/font'
import { useSweetalertTopEnd } from '@/js/sweetalert'
import { version } from "../js/version";

export default {
  components: {
    // Multiselect,
  },
  setup () {
    const router = useRouter();
    const branch_key = localStorage.getItem('branch_key')
    const form = reactive({
      username: '',
      password: '',
      pos_machine_id: 2,
      secret_key:branch_key,
      is_skip_secret_key: 1
    })
    const warehouse_location_id = ref(0);
    const warehouse_locations = ref("");
    const warehouse_location_options = ref([]);


    const pos_machines = ref("");
    const pos_machine_options = ref([]);
    
    const backend_url = process.env.VUE_APP_URL;

    const onSubmit = () => {
        axios.post(`${process.env.VUE_APP_URL}api/login`, form).then( response => {
            const success_response = response.data;
            localStorage.setItem('user', JSON.stringify(success_response))
            localStorage.setItem('role', success_response.role)
            localStorage.setItem('pos_machine_id', form.pos_machine_id)
            localStorage.setItem('warehouse_location_id', warehouse_location_id.value)
            let pos_machine = pos_machine_options.value.find(item => item.value === form.pos_machine_id).label;
            let pos_id = pos_machine_options.value.find(item => item.value === form.pos_machine_id).pos_id;
            let pos_no = pos_machine_options.value.find(item => item.value === form.pos_machine_id).pos_no;
            let pos_warehouse_location_id = pos_machine_options.value.find(item => item.value === form.pos_machine_id).pos_warehouse_location_id;
            let warehouse_location = "PhotoFair";
            localStorage.setItem('username', form.username)
            localStorage.setItem('pos_machine', pos_machine)
            localStorage.setItem('pos_id', pos_id)
            localStorage.setItem('pos_no', pos_no)
            localStorage.setItem('pos_warehouse_location_id', pos_warehouse_location_id)
            localStorage.setItem('warehouse_location', warehouse_location)

            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 1000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              })
              Toast.fire({
                icon: 'success',
                title: "Login success"
              })
              router.push("/orders");
        }).catch( error => {
            let error_response = error.response.data;
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              })
              Toast.fire({
                icon: 'error',
                title: error_response.message
              })
        });
    };

    const getPosMachines = () => { 
        api.get(`api/pos_machines`).then((response)=>{
            let success_response = response.data;
            pos_machines.value = success_response.data
            pos_machines.value.forEach(function(item){
              let arr = {
                value : item.id,
                label : `${item.name} - ${item.warehouse_location.name ? item.warehouse_location.name : '-'}`,
                pos_id : item.name,
                pos_no : item.pos_no,
                pos_warehouse_location_id : item.warehouse_location_id,
              };
              pos_machine_options.value.push(arr)
            })

        }).catch( error => {
            let error_response = error.response.data;
            useSweetalertTopEnd(1500, 'error', error_response.message)
        });
    }
    const getWarehouseLocation = () => { 
        api.get(`api/warehouse/locations`).then((response)=>{
            console.log(response.data)
            let success_response = response.data;
            warehouse_locations.value = success_response.data
            warehouse_locations.value.forEach(function(item){
              let arr = {
                value : item.id,
                label : item.name,
              };
              warehouse_location_options.value.push(arr)
            })

        }).catch( error => {
            let error_response = error.response.data;
            useSweetalertTopEnd(1500, 'error', error_response.message)
        });
    }

    onMounted(() => {
        getPosMachines();
        getWarehouseLocation();
    });


    return { 
      form, 
      onSubmit, 
      pos_machines, 
      pos_machine_options, 
      warehouse_locations, 
      warehouse_location_options, 
      warehouse_location_id,
      backend_url,
      version
      }
  }
}
</script>

<style scoped>

</style>