<template>
    <div v-if="order" id="receipt-content-page" class="text-left text-sm p-6 pt-2 print-area receipt">
    <span v-for="index in 2" :key="index">
        <div v-if="index!=1" class="page-break"></div>
        <div class="relative">
            <div
                id="watermark_cancel"
                class=""
                v-if="order.deleted_at != null"
            >
                <img
                    src="../../assets/image/cancel.png"
                    alt="cancel"
                    class="w-full"
                    id="image_cancel"
                />
            </div>
            <div class="text-center">
                <img
                    src="../../assets/image/stm-no-title.png"
                    alt="STM POS"
                    class="mb-3 w-20 inline-block"
                    id="photo_logo"
                />
                <p class="font-bold text-sm">{{ order.company.name_th }}</p>
                <p class="text-sm">{{ order.address }}</p>
                <p class="text-sm">โทร {{ order.company.phone }}</p>
            </div>
            <div class="mt-4 text-center">
                <p class="font-bold text-sm">
                    ใบรับเงิน
                </p>
            </div>
            <hr class="my-1" />
            <p class="text-center text-sm">{{ order.manual_order_no }}</p>
            <p class="text-center text-sm">
                Date :
                {{ moment(order.created_at).format("D/M/yyy LTS") }}
            </p>
            <div>
                <table class="w-full text-sm">
                    <tbody>
                        <tr>
                            <td width="120px">Cashier</td>
                            <td>:</td>
                            <td>{{ order.user.name }}</td>
                        </tr>
                        <tr>
                            <td class="">{{ order.payment_type }}</td>
                            <td v-if="order.payment_type == 'Online'">
                                :
                            </td>
                            <td v-if="order.payment_type == 'Online'">
                                {{ order.online_order_no }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <hr class="my-3" />
            <div>
                <table class="w-full text-sm">
                    <thead>
                        <tr>
                            <td class="text-center" colspan="4">
                                รายการสินค้า
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="pos_manual_order_lineitem_receipt in order.pos_manual_order_lineitem_receipts"
                            :key="
                                pos_manual_order_lineitem_receipt.model_id
                            "
                        >
                            <td class="text-center align-top">
                                {{
                                    pos_manual_order_lineitem_receipt.number
                                }}
                            </td>
                            <td class="text-left">
                                <span>{{
                                    pos_manual_order_lineitem_receipt.name
                                }}</span>
                            </td>
                            <td class="text-center align-top">
                                <p>
                                    {{
                                        pos_manual_order_lineitem_receipt.quantity
                                    }}
                                </p>
                            </td>
                            <td class="text-right align-top">
                                <p
                                    v-if="
                                        pos_manual_order_lineitem_receipt.total_not_discount !==
                                            ''
                                    "
                                >
                                    {{
                                        formatNumber(
                                            pos_manual_order_lineitem_receipt.total_not_discount
                                        )
                                    }}
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <hr class="my-2" />
            <div class="flex text-sm">
                <div class="flex-grow">รวมจำนวนสินค้า</div>
                <div class="text-right">
                    {{ formatNumber(total_product) }}
                </div>
            </div>
            <div class="flex text-sm">
                <div class="flex-grow">รวมเป็นเงินทั้งสิ้น</div>
                <div class="text-right">
                    {{ formatNumber(order.grand_total) }}
                </div>
            </div>
        </div>
    </span>
    </div>
</template>

<script>
import { ref, onMounted } from "vue";
import api from '@/services/auth';
import { useRoute } from "vue-router";
// import { useGetOrderByOrderNo } from "@/js/pos_order";
// import { useCompany } from "@/js/company";
import { useFormatNumber } from "@/js/format";
import moment from "moment";
import { useSweetalertTopEnd } from '@/js/sweetalert'

export default {
    name: "Receipt",
    setup() {
        const loading = ref(false);
        const order = ref(null);
        const route = useRoute();
        const formatNumber = useFormatNumber;
        const total_product = ref(0);
        const getOrder = (manual_order_id) => {
            api.get(`api/pos_manual_orders/${manual_order_id}`)
                .then((response)=>{
                    loading.value = false;
                    order.value = response.data.data
                    order.value.red
                    total_product.value = order.value.pos_manual_order_lineitems.reduce((accumulator, object) => {
                            return accumulator + object.quantity;
                        }, 0)

                }).catch( error => {
                    let error_response = error.response.data;
                    useSweetalertTopEnd(1500, 'error', error_response.message)
                });
        }

        onMounted( async () => {
            await getOrder(route.params.manual_order_id);
            await setTimeout(() => {window.print()}, 2000)
            await setTimeout(() => {window.close()}, 2000)
        });


        return {
            loading,
            order,
            moment,
            formatNumber,
            total_product,
        };
    },
};
</script>
<style scope>
@page {
    margin: 0;
}
@font-face {
    font-family: myFirstFont;
    src: url(../../assets/font/f25_bank_printer/F25_Bank_Printer_Bold.ttf);
}
@font-face {
    font-family: myFirstFont2;
    src: url(../../assets/font/kravitz/test.TTF);
}
@font-face {
    font-family: TahomaFont;
    src: url(../../assets/font/Tahoma-Font/TAHOMA_0.TTF);
}
#receipt-content {
    font-family: myFirstFont;
}
#image_void {
    width: 430px;
}
#watermark_void {
    position: absolute;
    opacity: 1;
    /** 
        Set a position in the page for your image
        This should center it vertically
    **/
    top: 4cm;
    left: 13.5cm;

    /** Your watermark should be behind every content**/
    z-index: -1000;
    transform: rotate(-35deg);
}
#image_cancel {
    width: 500px;
}
#watermark_cancel {
    position: absolute;
    opacity: 1;
    /** 
        Set a position in the page for your image
        This should center it vertically
    **/
    top: 1cm;
    left: 12.5cm;

    /** Your watermark should be behind every content**/
    z-index: -1000;
    transform: rotate(-35deg);
}
@media print {
    #image_void {
        width: 90%;
    }
    #watermark_void {
        position: absolute;
        opacity: 1;
        /** 
            Set a position in the page for your image
            This should center it vertically
        **/
        top: 4cm;
        left: 1cm;

        /** Your watermark should be behind every content**/
        z-index: -1000;
        transform: rotate(-35deg);
    }
    #image_cancel {
        width: 100%;
    }
    #watermark_cancel {
        position: absolute;
        opacity: 1;
        /** 
            Set a position in the page for your image
            This should center it vertically
        **/
        top: 0cm;
        left: 1cm;

        /** Your watermark should be behind every content**/
        z-index: -1000;
        transform: rotate(-35deg);
    }
    .page-break {page-break-after: always;}
}
</style>
