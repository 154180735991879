import axios from "axios";

const Auth = axios.create({
  baseURL: process.env.VUE_APP_URL,
  headers: {
    "Content-type": "application/json",
    "Accept": "application/json",
  },
});

export default Auth;