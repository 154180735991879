<template>
    <div class="flex flex-col w-full px-1 md:px-5 pb-2 gap-2 md:gap-4 overflow-y-auto">
        <div class="w-full">
            <Customer />
        </div>
        <div class="w-full">
            <Product />
        </div>
        <div class="flex flex-col md:flex-row gap-4">
            <div class="w-full md:w-1/2">
                <Payment />
            </div>
            <div class="w-full md:w-1/2">
                <Summary @onCheckButton="onCheckButton"/>
            </div>
        </div>
        <div class="w-full text-center">
            <button @click="save" :disabled="isButton" class="w-16 md:w-24 p-3 rounded-lg bg-green-400 hover:bg-green-600 text-white font-bold mr-5">SAVE</button>
            <router-link to="/manual-order" class="w-16 md:w-24 p-3 py-4 rounded-lg bg-red-400 hover:bg-red-600 text-white font-bold mr-2">CANCEL</router-link>
        </div>
    </div>
</template>

<script>
import api from '@/services/auth';
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from "vue-router";
import Customer from "@/components/manual-order/create/Customer";
import Product from "@/components/manual-order/create/Product";
import Summary from "@/components/manual-order/create/Summary";
import Payment from "@/components/manual-order/create/Payment";
import { useSweetalertTopEnd, useSweetalertError } from '@/js/sweetalert';

export default {
    name:"Create",
    components:{
        Customer,
        Product,
        Summary,
        Payment
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const isButton = ref(false)

        onMounted(() => {
            store.commit('manualOrder/resetForm')
        });
        function save(){
            api.post(`api/pos_manual_orders`, store.state.manualOrder.form)
                .then(() => {
                    useSweetalertTopEnd(1500, 'success', "Order created")
                    router.push("/manual-order");
                }).catch( error => {
                    useSweetalertError(
                        error.response.data?.error_message
                            ? error.response.data.error_message
                            : error.message
                    );
                })
        }
        const onCheckButton = (e) =>{
            isButton.value = e
        }
        return { save, onCheckButton, isButton }
    }
}
</script>