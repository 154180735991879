<template>
    <button @click="modal_new_customer = true"  :class="btn_style"><slot></slot></button>
    
    <div v-if="modal_new_customer" class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
      <div @click="modal_new_customer = false" class="modal-overlay absolute w-full h-full backdrop-filter backdrop-blur-sm backdrop-brightness-50"></div>
      
      <div class="modal-container bg-white w-1/2 mx-auto rounded shadow-lg z-50 overflow-y-auto">

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="modal-content py-4 text-left px-6">
          <!--Title-->
          <div class="flex justify-between items-center pb-3">
            <p class="font-bold">Create new customer</p>
            <div @click="modal_new_customer = false" class="modal-close cursor-pointer z-50">
              <i class="fas fa-times"></i>
            </div>
          </div>
          <hr class="mb-2">
          <!--Body-->
          <div>
            <form @submit.prevent="onSubmit" class="w-full pt-5">
              <div class="flex flex-wrap  mb-3">
                <div class="w-full px-3">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="full_name">
                    Customer Name <span class="text-red-500">*</span>
                  </label>
                  <input v-model="form.full_name" class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-green-400" id="full_name" type="text" placeholder="Full Name" required>
                  <p class="text-red-500 text-xs italic">{{ error_msg.full_name }}</p>
                </div>
              </div>
              <div class="flex flex-wrap  mb-3">
                <div class="w-full px-3">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="tax_no">
                    Tax 
                  </label>
                  <input v-model="form.tax_no" class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-green-400" id="tax_no" type="text" placeholder="Tax No">
                  <p class="text-red-500 text-xs italic">{{ error_msg.tax_no }}</p>
                </div>
              </div>
              <div class="flex flex-wrap  mb-3">
                <div class="w-full px-3">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="phone">
                    Phone
                  </label>
                  <input v-model="form.phone" class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-green-400" id="phone" type="text" placeholder="Phone">
                  <p class="text-red-500 text-xs italic">{{ error_msg.phone }}</p>
                </div>
              </div>
              <div class="flex flex-wrap  mb-3">
                <div class="w-full px-3">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="email">
                    Email
                  </label>
                  <input v-model="form.email" class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-green-400" id="email" type="text" placeholder="Email">
                  <p class="text-red-500 text-xs italic">{{ error_msg.email }}</p>
                </div>
              </div>
              
              <div class="flex flex-wrap  mb-1">
                <div class="w-full px-3">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="address">
                    Address
                  </label>
                  <input v-model="form.address" class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-green-400" id="address" type="text" placeholder="Address">
                  <p class="text-red-500 text-xs italic">{{ error_msg.address }}</p>
                </div>
              </div>
                
                <!--Footer-->
                <div class="flex justify-end pt-2">
                  <button @click="clearForm" type="button" class="p-3 rounded-lg bg-red-400 hover:bg-red-600 text-white font-bold mr-2">Clear</button>
                  <button type="submit" class="p-3 rounded-lg bg-green-400 hover:bg-green-600 text-white font-bold mr-2">Save</button>
                </div>
            </form>
          </div>
          
        </div>
      </div>
    </div>
</template>

<script>
import { ref, reactive } from "vue"
import api from '@/services/auth'
import { useSweetalertTopEnd } from '@/js/sweetalert'

export default {
  name: 'CustomerAdd',
  props:['btn_style'],
  emits: ['saveCustomer'],
  setup(props,{emit}){
    const modal_new_customer = ref(false)
    const form = reactive({
      full_name : '',
      tax_no : '',
      phone : '',
      email : '',
      address : '',
    })
    const error_msg = reactive({
      full_name : '',
      tax_no : '',
      phone : '',
      email : '',
      address : '',
    })

    
    const onSubmit = () => { 
      api.post('api/customers',
          form
      ).then((response)=>{
          closeModal()
          clearForm()
          // console.log(response.data)
          let success_response = response.data;
          useSweetalertTopEnd(1500, 'success', success_response.message)

          emit('saveCustomer',response.data)

      }).catch( error => {
          clearError()
          let error_response = error.response.data;
          useSweetalertTopEnd(1500, 'error', error_response.message)
          if(error_response.status == 422){
            for (const key in error_response.errors) {
                error_msg[key] = error_response.errors[key][0]
                // console.log(`${key}: ${error_response.errors[key]}`);
            }
          }
      });
    }
    const closeModal = () => {
      modal_new_customer.value = false
    }
    const clearForm = () => {
      form.full_name = ''
      form.tax_no = ''
      form.phone = ''
      form.email = ''
      form.address = ''
      clearError()
    }
    const clearError = () => {
      error_msg.full_name = ''
      error_msg.tax_no = ''
      error_msg.phone = ''
      error_msg.email = ''
      error_msg.address = ''
    }

    return { modal_new_customer, form, error_msg, onSubmit, clearForm, clearError, closeModal }
  }
}
</script>
<style>
</style>