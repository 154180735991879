<template>
    <div class="flex flex-col w-full pb-8 bg-white rounded-2xl">
        <div class="flex items-center justify-between mb-2 p-5">
            <h2 class="text-sm font-semibold text-gray-700 md:text-xl dark:text-gray-200">
                Product
            </h2>
            <button class="bg-blue-400 hover:bg-blue-600 py-1 px-2 rounded-lg text-white font-medium" @click="onOpenProduct">
                Add product
            </button>
        </div>
        <div class="w-full h-full overflow-hidden rounded-lg shadow-sm overflow-x-auto md:p-5 p-2">
            <table class="w-full whitespace-no-wrap">
                <thead>
                    <tr class="text-xs font-semibold tracking-wide text-left text-gray-600 uppercase border-b dark:border-gray-700 bg-gray-100 dark:text-gray-400 dark:bg-gray-800">
                        <th class="px-4 py-3 text-right">#</th>
                        <th class="px-4 py-3 text-center whitespace-nowrap">Barcode</th>
                        <th class="py-3">Product Name</th>
                        <th class="px-4 py-3 text-center whitespace-nowrap">Serial No.</th>
                        <th class="px-2 py-3 text-right whitespace-nowrap">Price (฿)</th>
                        <th class="px-2 py-3 text-right whitespace-nowrap">Quantity</th>
                        <th class="px-2 py-3 text-right whitespace-nowrap">DISCOUNT (฿)</th>
                        <th class="px-2 py-3 text-right whitespace-nowrap">Total Price (฿)</th>
                        <th class="px-4 py-3"></th>
                    </tr>
                </thead>
                <tbody class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                    <tr v-if="form.pos_manual_order_lineitems.length === 0">
                        <td class="px-2 py-3 text-center text-gray-500" colspan="8">No data records</td>
                    </tr>
                    <tr v-for="(lineitem, index) in form.pos_manual_order_lineitems" :key="index">
                        <td class="px-2 py-3 text-right">{{ index+1 }}</td>
                        <td class="px-2 py-3 text-center">{{ lineitem.barcode }}</td>
                        <td class="px-2 py-3">{{ lineitem.document_model_name }}</td>
                        <td class="px-2 py-3 text-center">{{ lineitem.serial }}</td>
                        <td class="px-2 py-3 text-right">{{ useFormatNumber(lineitem.ppe) }}</td>
                        <td class="px-2 py-3 text-right">{{ lineitem.quantity }}</td>
                        <td class="px-2 py-3 text-right">{{ useFormatNumber(lineitem.discount_baht) }}</td>
                        <td class="px-2 py-3 text-right">{{ useFormatNumber(lineitem.total) }}</td>
                        <td class="px-2 py-3 text-right">
                            <button
                                type="button"
                                class="px-2 py-1 mx-1 mt-1 text-sm font-medium shadow leading-5 text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-red-700 focus:outline-none"
                                @click="removeLineitem(lineitem.uuid)"
                                >
                                <em class="far fa-trash-alt"></em>
                            </button>

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ProductModal v-if="isOpenProduct" :on-close="onCloseProduct" />
    </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from 'vuex';
import ProductModal from "./ProductModal";
import { useFormatNumber } from '@/js/format';

export default {
    name:"Product",
    components:{ ProductModal },
    setup(){
        const store = useStore();
        const isOpenProduct = ref(false);

        const onOpenProduct = () => isOpenProduct.value = true;
        const onCloseProduct = () => isOpenProduct.value = false;
        const removeLineitem = (uuid) => {
            if(confirm("Do you want to delete ?")){
                store.commit('manualOrder/removeLineitem', uuid)
            }
        };
        return {
            form: computed(() => store.state.manualOrder.form),
            isOpenProduct,
            onOpenProduct,
            onCloseProduct,
            useFormatNumber,
            removeLineitem
        }
    }
}
</script>