<template>
    <div class="flex flex-col w-full h-max px-1 md:px-5 mx-1 md:mx-2 bg-white rounded-2xl pb-2">
        <div class="flex flex-col md:flex-row items-center justify-between mt-5 mb-2">
            <h2 class="my-1 md:my-6 text-sm font-semibold text-gray-700 md:text-xl dark:text-gray-200">
                Clearance order ({{ total }})
            </h2>
            <div class="flex flex-col md:flex-row justify-center flex-1 lg:mr-32">
                <div class="relative w-full max-w-xl ml-4 mr-6 focus-within:text-gray-500">
                    <div class="absolute inset-y-0 flex items-center pl-2">
                        <i class="fas fa-search"></i>
                    </div>
                    <form @submit.prevent="submitSearchForm">
                        <input
                            v-model="keyword"
                            class="w-full py-2 pl-8 pr-2 text-sm text-gray-700 placeholder-gray-600 bg-gray-100 border-0 rounded-md"
                            type="text"
                            placeholder="Search"
                            aria-label="Search"
                        />
                    </form>
                </div>
                <div class="flex flex-row justify-center">
                    <button @click="submitSearchForm" class="flex items-center justify-between px-4 py-1.5 mx-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-500 border border-transparent rounded-lg active:bg-purple-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-purple">
                        <i class="fas fa-search mr-2"></i>
                        <span class="hidden md:block">Search</span>
                    </button>
                    <button @click="resetSearchForm" class="flex items-center justify-between px-4 py-1.5 mx-1 text-sm font-medium leading-5 text-red-500 transition-colors duration-150 border-red-500 border border-transparent rounded-lg active:bg-purple-600 hover:bg-red-500 hover:text-white focus:outline-none focus:shadow-outline-purple">
                        <i class="fas fa-sync mr-2"></i>
                        <span class="hidden md:block">Clear</span>
                    </button>
                </div>
            </div>

            <router-link to="/manual-order/create" class="flex items-center justify-between px-4 py-2 mx-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-green-500 border border-transparent rounded-lg active:bg-purple-600 hover:bg-green-700 focus:outline-none focus:shadow-outline-purple">
                <span class="mr-2 font-bold">+</span>
                <span class="hidden md:block">Create</span>
            </router-link>
        </div>

        <div class="w-full overflow-hidden rounded-lg shadow-sm">
            <div class="h-full overflow-y-auto">
                <table class="w-full whitespace-no-wrap">
                    <thead>
                        <tr class="text-xs font-semibold tracking-wide text-left text-gray-600 uppercase border-b dark:border-gray-700 bg-gray-100 dark:text-gray-400 dark:bg-gray-800">
                            <th class="px-4 py-3">#</th>
                            <th class="px-4 py-3">ORDER No.</th>
                            <th class="px-4 py-3">CUSTOMER</th>
                            <th class="px-4 py-3 text-right">Grand total</th>
                            <th class="px-4 py-3"></th>
                        </tr>
                    </thead>
                    <tbody class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
        
                        <tr v-for="(order, index) in pos_manual_orders" :key="order.id" class="text-gray-700 dark:text-gray-400 hover:bg-blue-100">
                            <td class="px-4 py-3 text-sm" :class="{ 'line-through text-red-500': order.deleted_at}">{{ (current_page-1) * per_page + (index+1) }}</td>
                            <td class="px-4 py-3" :class="{ 'line-through text-red-500': order.deleted_at}">
                                <div class="flex items-center text-sm">
                                    <div>
                                        <p class="font-semibold">{{ order.manual_order_no }}</p>
                                        <p class="text-xs">Created {{ formatDate(order.created_at) }}</p>
                                    </div>
                                </div>
                            </td>
                            <td class="px-4 py-3 text-sm" :class="{ 'line-through text-red-500': order.deleted_at}">{{ order.customer_full_name }}</td>
                            <td class="px-4 py-3 text-sm text-right" :class="{ 'line-through text-red-500': order.deleted_at}">
                                <p class="">{{ formatNumber(order.grand_total) }}</p>
                                <p class="text-xs">{{ order.payment_type }}</p>
                            </td>
                            <td class="px-4 py-3 text-sm text-center flex justify-center">
                                <button @click="openModalReceipt(order.id)" class="px-4 py-2 mx-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-green-400 border border-transparent rounded-lg active:bg-purple-600 hover:bg-green-500 focus:outline-none focus:shadow-outline-purple">
                                    <i class="fas fa-print"></i>
                                </button>
                                <div v-if="order.deleted_at==null">
                                    <button @click="deleteManualOrder(order.id)" class="px-4 py-2 mx-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-purple">
                                        <i class="far fa-trash-alt"></i>
                                    </button>
                                </div>
                                <div v-if="order.deleted_at">
                                    <button class="px-4 py-2 mx-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-red-300 border border-transparent rounded-lg active:bg-purple-600 cursor-not-allowed focus:outline-none focus:shadow-outline-purple">
                                        <i class="far fa-trash-alt"></i>
                                    </button>
                                </div>
                            </td>
                            
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
                <VueTailwindPagination
                class="w-full"
                    :current="current_page"
                    :total="total"
                    :per-page="per_page"       
                    @page-changed="onPageClick($event)"         
                />
        <!-- Page -->
        <!--Modal Receipt-->
        <Receipt v-if="modal_receipt" :manual_order_id="manual_order_id" :on-close="onCloseReceipt" />
        <!--Modal Receipt-->
    </div>
</template>

<script>
import { ref, onMounted } from "vue";
import api from '@/services/auth'
import { useSweetalertTopEnd } from '@/js/sweetalert'
import "@ocrv/vue-tailwind-pagination/dist/style.css"
import VueTailwindPagination from '@ocrv/vue-tailwind-pagination'
import swal from 'sweetalert2'
import { useFormatNumber, useFormatDate } from '@/js/format'
import Receipt from '@/components/manual-order/create/Receipt.vue'

export default {
    name:"ManualOrderIndex",
    components:{
        VueTailwindPagination,
        Receipt
    },
    setup(){
        const pos_manual_orders = ref([]);
        const keyword = ref("");
        const total = ref(0);
        const current_page = ref(1);
        const per_page = ref(0);
        const Swal = swal
        const formatNumber = useFormatNumber;
        const formatDate = useFormatDate;
        const modal_receipt = ref(false);
        const manual_order_id = ref("");

        const getManualOrders = (page,keyword_param) => { 
            api.get(`api/pos_manual_orders?page=${page}&keyword=${keyword_param}`).then((response)=>{
                let success_response = response.data;
                pos_manual_orders.value = success_response.data.data
                total.value = success_response.data.total
                current_page.value = success_response.data.current_page
                per_page.value = success_response.data.per_page

            }).catch( error => {
                let error_response = error.response.data;
                useSweetalertTopEnd(1500, 'error', error_response.message)
            });
        }

        onMounted(() => {
            getManualOrders(current_page.value, keyword.value);
        });


        function onPageClick(event){
            current_page.value = event

            getManualOrders(current_page.value, keyword.value);
        }

        
        function deleteManualOrder(id){
            Swal.fire({
                title: 'Do you want to delete?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    api.delete(`api/pos_manual_orders/${id}`).then(()=>{
                        getManualOrders(current_page.value, keyword.value);

                    }).catch( error => {
                        let error_response = error.response.data;
                        useSweetalertTopEnd(1500, 'error', error_response.message)
                    });
                }
            })
        }

        function submitSearchForm(){
            getManualOrders(1, keyword.value);
        }
        function resetSearchForm(){
            keyword.value = ""
            getManualOrders(1, keyword.value);
        }
        function openModalReceipt(id){
            manual_order_id.value = id;
            modal_receipt.value = true;
        }
        function onCloseReceipt(){
            manual_order_id.value = "";
            modal_receipt.value = false;
        }

        return { 
                pos_manual_orders, 
                keyword, 
                getManualOrders,
                total,
                current_page,
                per_page,
                onPageClick,
                formatNumber,
                formatDate,
                deleteManualOrder,
                submitSearchForm,
                resetSearchForm,
                modal_receipt,
                openModalReceipt,
                onCloseReceipt,
                manual_order_id
            }
  }
  
}
</script>