import { useNumberTwoDecimal } from '@/js/format';

const state = () => ({
    orders: [],
    form: {
        customer_id: null,
        customer_full_name: null,
        customer_address: null,
        customer_phone: null,
        total_price: 0,
        total_pay: 0,
        discount_baht: 0,
        discount_percent: 0,
        vat: parseFloat(process.env.VUE_APP_VAT_RATE),
        vat_type: 1,
        price_before_vat: 0,
        vat_price: 0,
        grand_total: 0,
        payment_type: null,
        payment_option: null,
        payment_note: null,
        total_price_sum_discount: 0,
        warehouse_location_id: localStorage.getItem('warehouse_location_id'),
        pos_machine_id: localStorage.getItem('pos_machine_id'),
        pos_manual_order_lineitems : [],
    },

})
const getters = {
}
  
const actions = {
}
  
const mutations = {
    setLineitem (state, value) {
        state.form.pos_manual_order_lineitems.push(value);
        this.commit('manualOrder/setSummary');
    },
    removeLineitem (state, uuid) {
        state.form.pos_manual_order_lineitems = state.form.pos_manual_order_lineitems.filter(
            (item) => item.uuid !== uuid
        );
        this.commit('manualOrder/setSummary');
    },
    setSummary(state) {
        state.form.total_price_sum_discount = state.form.pos_manual_order_lineitems.reduce(
            (accumulator, object) => {
                return accumulator + useNumberTwoDecimal(object.total);
            },
            0
        );
        state.form.total_price = state.form.pos_manual_order_lineitems.reduce(
            (accumulator, object) => {
                return (
                    accumulator +
                    useNumberTwoDecimal(object.quantity) *
                        useNumberTwoDecimal(object.ppe)
                );
            },
            0
        );

        if (state.form.vat_type === 1) {
            state.form.price_before_vat =
                state.form.total_price_sum_discount /
                ((100 + useNumberTwoDecimal(process.env.VUE_APP_VAT_RATE)) / 100);
            state.form.vat_price =
                state.form.total_price_sum_discount -
                state.form.price_before_vat;
            state.form.grand_total =
                state.form.price_before_vat + state.form.vat_price;
            // Excluding vat
        } else if (state.form.vat_type === 2) {
            state.form.price_before_vat = state.form.total_price_sum_discount;
            state.form.vat_price =
                (state.form.total_price_sum_discount * state.form.vat) / 100;
            state.form.grand_total =
                state.form.total_price_sum_discount + state.form.vat_price;
            // Disabled vat
        } else if (state.form.vat_type === 3) {
            state.form.price_before_vat = state.form.total_price_sum_discount;
            state.form.vat_price = 0;
            state.form.grand_total = state.form.total_price_sum_discount;
        }
        state.form.discount_baht = state.form.pos_manual_order_lineitems.reduce(
            (accumulator, object) => {
                return (
                    accumulator +
                    useNumberTwoDecimal(object.quantity) *
                        useNumberTwoDecimal(object.discount_baht)
                );
            },
            0
        );
        const totalPpe = state.form.pos_manual_order_lineitems.reduce((accumulator, object) => {
            return (
                accumulator +
                useNumberTwoDecimal(object.quantity) *
                    useNumberTwoDecimal(object.ppe)
            );
        }, 0);
        state.form.discount_percent = (state.form.discount_baht * 100) / useNumberTwoDecimal(totalPpe);
        state.form.grand_total = useNumberTwoDecimal(state.form.grand_total) ;
    },
    resetForm(state){
        state.form = {
            customer_id: null,
            customer_full_name: null,
            customer_address: null,
            customer_phone: null,
            total_price: 0,
            total_pay: 0,
            discount_baht: 0,
            discount_percent: 0,
            vat: parseFloat(process.env.VUE_APP_VAT_RATE),
            vat_type: 1,
            price_before_vat: 0,
            vat_price: 0,
            grand_total: 0,
            payment_type: null,
            payment_option: null,
            payment_note: null,
            total_price_sum_discount: 0,
            warehouse_location_id: localStorage.getItem('warehouse_location_id'),
            pos_machine_id: localStorage.getItem('pos_machine_id'),
            pos_manual_order_lineitems : [],
        }
    }
}
  
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}