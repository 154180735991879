<template>
    <div class="flex flex-col w-full p-5 pb-8 bg-white rounded-2xl">
        <div class="flex items-center justify-between mb-2">
            <h2 class="text-sm font-semibold text-gray-700 md:text-xl dark:text-gray-200">
                Customer
            </h2>
        </div>
        <div class="grid grid-cols-2 gap-4">
            <div class="col-span-2 form-group">
                <div class="w-full md:w-1/2">
                    <multiselect
                        v-model="form.customer_id"
                        placeholder="Search for customer"
                        :options="customerOptions"
                        :loading="customerIsLoading"
                        searchable
                        clear-on-select
                        @search-change="get_customer"
                        @input="select_customer"
                        >
                        <template #caret>
                            <span class="arrow absolute text-center top-2 right-3">
                                <i class="fa fa-search" aria-hidden="true"></i>
                            </span>
                        </template>
                        <template v-slot:noResult>No results found.</template>
                        <template v-slot:noOptions>Please enter character to search.</template>
                    </multiselect>
                    
                </div>
            </div>
            <div class="col-span-2 md:col-span-1 form-group">
                <input 
                v-model="form.customer_full_name"
                type="text" class="form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="customer_full_name"
                aria-describedby="customer_full_name" placeholder="Full name">
            </div>
            <div class="col-span-2 md:col-span-1 form-group">
                <input 
                v-model="form.customer_phone"
                type="text" class="form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="customer_phone"
                aria-describedby="customer_phone" placeholder="Phone">
            </div>
            <div class="col-span-2 form-group">
                <input 
                v-model="form.customer_address"
                type="text" class="form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="customer_address"
                aria-describedby="customer_address" placeholder="Address">
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
// import api from '@/services/auth';
import { ref, computed } from "vue";
import { useStore } from 'vuex';
import Multiselect from '@vueform/multiselect';
import { useSweetalertError } from '@/js/sweetalert'

export default {
    name:"Customer",
    components: {
        Multiselect,
    },
    setup(){
        const store = useStore();
        const customerIsLoading = ref(false);
        const customerOptions = ref([]);

        function get_customer(query){
            if(query){
                customerIsLoading.value = true;
                customerOptions.value = []
                axios.get(`${process.env.VUE_APP_HOST_CHAT}customers/no_auth`, {params:{search_txt:query, page_type:1}})
                // api.get(`api/customers?page_size=All&keyword=${query}`)
                    .then(response => {
                        customerIsLoading.value = false;
                        let success_response = response.data;
                        success_response.data.forEach(function(item){
                            let arr = {
                                value : item.id,
                                label : `${item.phone} - ${item.full_name}`,
                                full_name : item?.full_name,
                                phone : item?.phone,
                                address : `${item.address} ${item.tumbol_th ? item.tumbol_th : ''} ${item.amper_th ? item.amper_th : ''} ${item.province_th ? item.province_th : '' } ${item.postal}`,
                            };
                            customerOptions.value.push(arr)
                        })
                    }).catch( error => {
                        customerIsLoading.value = false;
                        useSweetalertError(
                            error.response.data?.error_message
                                ? error.response.data.error_message
                                : error.message
                        );
                    })
            }
        }
        function select_customer(id){
            store.state.manualOrder.form.customer_full_name = customerOptions.value.find(item => item.value == id)?.full_name;
            store.state.manualOrder.form.customer_phone = customerOptions.value.find(item => item.value == id)?.phone;
            store.state.manualOrder.form.customer_address = customerOptions.value.find(item => item.value == id)?.address;
            // this.phone = this.customer_object ? this.customer_object.phone : null;
            // this.email = this.customer_object ? this.customer_object.email : null;
            // this.postal = this.customer_object ? this.customer_object.postal : null;
            // this.province_object = this.customer_object ? this.customer_object.province : null;
            // this.amper_object = this.customer_object ? this.customer_object.amper : null;
            // this.tumbol_object = this.customer_object ? this.customer_object.tumbol : null;
            // this.address = this.customer_object ? this.customer_object.address : null;
        }
        return {
            payment_type_lists: computed(() => store.state.payment.payment_type_lists),
            form: computed(() => store.state.manualOrder.form),
            get_customer,
            customerIsLoading,
            customerOptions,
            select_customer,
        }
    }
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>