import { createStore } from 'vuex'
import manualOrder from './modules/manual-order'
import payment from './modules/payment'

export default createStore({
  state: {
    pos_machine_id:null,
    isLoading:false,
  },
  mutations: {
    setPosId(state, n){
      state.pos_machine_id = n
    },
    setLoading(state, isLoading){
      state.isLoading = isLoading
    }
  },
  actions: {
  },
  modules: {
    manualOrder,
    payment
  }
})
