import { ref } from "vue";
import api from '@/services/auth'
import { useSweetalertTopEnd } from '@/js/sweetalert'
import Swal from 'sweetalert2'

export function useGetOrderByOrderNo(){
    const order = ref([]);
    const total_product = ref(0);
    const line_items = ref([]);
    const loading = ref(0);

    const getOrderByOrderNo = async (order_no) => { 
        await api.get(`api/pos_orders/${order_no}`).then((response)=>{
            let success_response = response.data;
            order.value = success_response.data
            loading.value = true
            success_response.data.pos_order_line_items.forEach(function(item){
                let obj_cart = {
                    model_shelf: `${item.model_id}_${item.warehouse_shelf_id}`,
                    model_id: item.model_id,
                    product_id: item.product_id,
                    serial_status: item.serial_status,
                    serial: item.serial,
                    warehouse_shelf_id: item.warehouse_shelf_id,
                    shelved_address: item.warehouse_shelf.shelved_address,
                    photo: item.model.photo,
                    name: item.model.name,
                    stock_quantity: item.stock_quantity,
                    quantity: item.quantity,
                    price: item.ppe,
                    discount_baht:item.discount_baht,
                    discount_percent:item.discount_percent
                }
                line_items.value.push(obj_cart);
            })

            total_product.value = order.value.pos_order_line_items.reduce(function(total, currentValue){
                return total + currentValue.quantity
              }, 0)
        }).catch( error => {
            let error_response = error.response.data;
            useSweetalertTopEnd(1500, 'error', error_response.message)
        });
    }
    return { order, total_product, line_items, getOrderByOrderNo, loading };
}
export function alert_is_order_management(){
    Swal.fire({
        icon: 'error',
        title: 'Order',
        text: 'Please take this action on The Order Management system',
        confirmButtonColor: '#3085d6',
        confirmButtonText:'OK',
    });
}