<template>

    <div v-if="modal_form_receipt_props && loading" class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
      <div @click="closeModalReceipt" class="modal-overlay absolute w-full h-full backdrop-filter backdrop-blur-sm backdrop-brightness-50"></div>
      
      <div class="modal-container bg-white w-1/2 mx-auto rounded shadow-lg z-50 overflow-y-auto">

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="modal-content py-4 text-left px-6">
          <!--Title-->
          <div class="flex justify-between items-center pb-3">
            <p class="font-bold">Invoice No : {{ tax_invoice_no_props }}</p>
            <div @click="closeModalReceipt" class="modal-close cursor-pointer z-50">
              <em class="fas fa-times"></em>
            </div>
          </div>
          <hr class="mb-2">
          <!--Body-->
          <div>
            <form @submit.prevent="onSubmitReceipt" class="w-full pt-5">
                <div class="flex flex-wrap  mb-3">
                    <div class="w-full px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="receipt_date">
                        Receipt Date <span class="text-red-500">*</span>
                    </label>
                    <input v-model="form.receipt_date" :readonly="form.receipt_no!=''" type="date" @keydown="dontKey" :class="form.receipt_no!='' ? 'bg-gray-100' : ''" class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-green-400" id="receipt_date" required>
                    <p class="text-red-500 text-xs italic">{{ error_msg.receipt_date }}</p>
                    </div>
                </div>
                <div class="flex flex-wrap  mb-3">
                    <div class="w-full px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="receipt_date">
                        Due Date
                    </label>
                    <input v-model="form.due_date" type="date" @keydown="dontKey" class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-green-400" id="due_date">
                    <p class="text-red-500 text-xs italic">{{ error_msg.due_date }}</p>
                    </div>
                </div>
                <div class="flex flex-wrap mb-3">
                    <div class="w-full px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="company_id">
                        Company <span class="text-red-500">*</span>
                    </label>
                    <input v-model="form.company_name" type="text" readonly class="appearance-none block w-full text-gray-700 border border-gray-200 bg-gray-100 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-green-400" id="company" required>
                    </div>
                </div>
                <div class="flex flex-wrap  mb-3">
                    <div class="w-full px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="Note">
                        Note
                    </label>
                    <textarea v-model="form.note" name="note" id="note" cols="20" rows="5" class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-green-400"></textarea>
                    <p class="text-red-500 text-xs italic">{{ error_msg.note }}</p>
                    </div>
                </div>
                
                <!--Footer-->
                <div class="flex justify-end pt-2">
                  <button @click="clearForm" type="button" class="p-3 rounded-lg bg-red-400 hover:bg-red-600 text-white font-bold mr-2">Clear</button>
                  <button type="submit" class="p-3 rounded-lg bg-green-400 hover:bg-green-600 text-white font-bold mr-2">Save</button>
                </div>
            </form>
          </div>
          
        </div>
      </div>
    </div>
</template>

<script>
import { reactive, onMounted } from "vue"
import api from '@/services/auth'
import { useSweetalertTopEnd } from '@/js/sweetalert'
import { useGetInvoiceByInvoiceNo } from '@/js/pos_order_invoice'
import moment from 'moment'

export default {
  name: 'ReceiptModal',
  props:['modal_form_receipt_props', 'tax_invoice_no_props'],
  emits: ['closeModalReceipt','saveReceipt'],

  setup(props,{emit}){
    const { tax_invoice, getInvoiceByInvoiceNo, loading } = useGetInvoiceByInvoiceNo();

    const form = reactive({
      receipt_no : '',
      receipt_date : '',
      due_date : '',
      company_name : '',
      note : '',
    })
    const error_msg = reactive({
      receipt_date : '',
      due_date : '',
      company_name : '',
      note : '',
    })


    onMounted(() => {
        getInvoiceByInvoiceNo(props['tax_invoice_no_props']).then(()=>{
            form.company_name = tax_invoice.value.company.initial
            if(tax_invoice.value.receipt){
                form.receipt_no = tax_invoice.value.receipt.document_no
                form.receipt_date = moment(String(tax_invoice.value.receipt.document_date)).format('YYYY-MM-DD')
                form.due_date = moment(String(tax_invoice.value.receipt.due_date)).format('YYYY-MM-DD')
                form.note = tax_invoice.value.receipt.remark
            }
        })
        
    });
    const onSubmitReceipt = () => { 
      api.post(`api/pos_order_invoices/${props['tax_invoice_no_props']}/receipt`,{
          receipt_no : form.receipt_no,
          receipt_date : form.receipt_date,
          due_date : form.due_date,
          note : form.note
      }).then((response)=>{
          closeModalReceipt()
          clearForm()
          let success_response = response.data;
          useSweetalertTopEnd(1500, 'success', success_response.message)

          emit('saveReceipt',response.data)

      }).catch( error => {
          clearError()
          let error_response = error.response.data;
          useSweetalertTopEnd(1500, 'error', error_response.message)
          if(error_response.status == 422){
            for (const key in error_response.errors) {
                error_msg[key] = error_response.errors[key][0]
            }
          }
      });
    }
    const closeModalReceipt = () => {
        clearForm()
        emit('closeModalReceipt', "")
    }
    const clearForm = () => {
      form.receipt_date = ''
      form.due_date = ''
      form.note = ''
      clearError()
    }
    const clearError = () => {
      error_msg.receipt_date = ''
      error_msg.due_date = ''
      error_msg.note = ''
    }
    function dontKey(event){
      event.preventDefault();
      return false;
    }
    return { tax_invoice, loading, form, error_msg, onSubmitReceipt, clearForm, clearError, closeModalReceipt, dontKey }
  }
}
</script>
<style>
</style>