<template>
    <div class="flex flex-col w-full h-max px-5 mx-2 bg-white rounded-2xl pb-2">
        <div class="flex items-center justify-between mt-5 mb-2">
            <h2 class="my-6 text-sm font-semibold text-gray-700 md:text-xl dark:text-gray-200">
                Customer ({{ total }})
            </h2>
            <div class="flex justify-center flex-1 lg:mr-32">
                <div class="relative w-full max-w-xl ml-4 mr-6 focus-within:text-gray-500">
                    <div class="absolute inset-y-0 flex items-center pl-2">
                        <i class="fas fa-search"></i>
                    </div>
                    <form @submit.prevent="submitSearchForm">
                        <input
                            v-model="keyword"
                            class="w-full py-2 pl-8 pr-2 text-sm text-gray-700 placeholder-gray-600 bg-gray-100 border-0 rounded-md"
                            type="text"
                            placeholder="Search"
                            aria-label="Search"
                        />
                    </form>
                </div>
                <div>
                    <button @click="submitSearchForm" class="flex items-center justify-between px-4 py-1.5 mx-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-500 border border-transparent rounded-lg active:bg-purple-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-purple">
                        <i class="fas fa-search mr-2"></i>
                        <span>Search</span>
                    </button>
                </div>
                <div>
                    <button @click="resetSearchForm" class="flex items-center justify-between px-4 py-1.5 mx-1 text-sm font-medium leading-5 text-red-500 transition-colors duration-150 border-red-500 border border-transparent rounded-lg active:bg-purple-600 hover:bg-red-500 hover:text-white focus:outline-none focus:shadow-outline-purple">
                        <i class="fas fa-sync mr-2"></i>
                        <span>Clear</span>
                    </button>
                </div>
            </div>

            <CustomerAdd @saveCustomer="getCustomers(1,'')" btn_style="flex items-center justify-between px-4 py-2 mx-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-green-500 border border-transparent rounded-lg active:bg-purple-600 hover:bg-green-700 focus:outline-none focus:shadow-outline-purple">
                <span class="mr-2 font-bold">+</span>
                <span>Create</span>
            </CustomerAdd>
        </div>

        <div class="w-full overflow-hidden rounded-lg shadow-sm">
            <div class="h-full overflow-y-auto">
                <table class="w-full whitespace-no-wrap">
                    <thead>
                        <tr class="text-xs font-semibold tracking-wide text-left text-gray-600 uppercase border-b dark:border-gray-700 bg-gray-100 dark:text-gray-400 dark:bg-gray-800">
                            <th class="px-4 py-3">#</th>
                            <th class="px-4 py-3">Name</th>
                            <th class="px-4 py-3">Tax</th>
                            <th class="px-4 py-3">Phone</th>
                            <th class="px-4 py-3">Address</th>
                            <th class="px-4 py-3"></th>
                        </tr>
                    </thead>
                    <tbody class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
        
                        <tr v-for="(customer, index) in customers" :key="customer.id" class="text-gray-700 dark:text-gray-400 hover:bg-blue-100">
                            <td class="px-4 py-3 text-sm">{{ (current_page-1) * per_page + (index+1) }}</td>
                            <td class="px-4 py-3">
                                <div class="flex items-center text-sm">
                                    <div>
                                        <p class="font-semibold">{{ customer.full_name }}</p>
                                    </div>
                                </div>
                            </td>
                            <td class="px-4 py-3 text-sm">{{ customer.tax_no }}</td>
                            <td class="px-4 py-3 text-sm">{{ customer.phone }}</td>
                            <td class="px-4 py-3 text-sm">{{ customer.address }}</td>
                            <td class="px-4 py-3 text-sm text-center">
                                <button @click="openModalEditCustomer(customer.id)" class="px-4 py-2 mx-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-yellow-400 border border-transparent rounded-lg active:bg-purple-600 hover:bg-yellow-500 focus:outline-none focus:shadow-outline-purple">
                                    <i class="far fa-edit"></i>
                                </button>
                                <button @click="deleteCustomer(customer.id)" class="px-4 py-2 mx-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-purple">
                                    <i class="far fa-trash-alt"></i>
                                </button>
                            </td>
                            
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
                <VueTailwindPagination
                    :current="current_page"
                    :total="total"
                    :per-page="per_page"       
                    @page-changed="onPageClick($event)"         
                />
        <!-- Page -->
    </div>
    <div v-if="modal_edit_customer" class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
      <div @click="modal_edit_customer = false" class="modal-overlay absolute w-full h-full backdrop-filter backdrop-blur-sm backdrop-brightness-50"></div>
      
      <div class="modal-container bg-white w-1/2 mx-auto rounded shadow-lg z-50 overflow-y-auto">

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="modal-content py-4 text-left px-6">
          <!--Title-->
          <div class="flex justify-between items-center pb-3">
            <p class="font-bold">Update customer</p>
            <div @click="modal_edit_customer = false" class="modal-close cursor-pointer z-50">
              <i class="fas fa-times"></i>
            </div>
          </div>
          <hr class="mb-2">
          <!--Body-->
          <div>
            <form @submit.prevent="onSubmitEdit(form.id)" class="w-full pt-5">
              <div class="flex flex-wrap  mb-4">
                <div class="w-full px-3">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="full_name">
                    Customer Name <span class="text-red-500">*</span>
                  </label>
                  <input v-model="form.full_name" class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-green-400" id="full_name" type="text" placeholder="Full Name" required>
                  <p class="text-red-500 text-xs italic">{{ error_msg.full_name }}</p>
                </div>
              </div>
              <div class="flex flex-wrap  mb-4">
                <div class="w-full px-3">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="tax_no">
                    Tax 
                  </label>
                  <input v-model="form.tax_no" class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-green-400" id="tax_no" type="text" placeholder="Tax No">
                  <p class="text-red-500 text-xs italic">{{ error_msg.tax_no }}</p>
                </div>
              </div>
              <div class="flex flex-wrap  mb-4">
                <div class="w-full px-3">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="phone">
                    Phone 
                  </label>
                  <input v-model="form.phone" class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-green-400" id="phone" type="text" placeholder="Phone">
                  <p class="text-red-500 text-xs italic">{{ error_msg.phone }}</p>
                </div>
              </div>
              <div class="flex flex-wrap  mb-4">
                <div class="w-full px-3">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="email">
                    Email
                  </label>
                  <input v-model="form.email" class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-green-400" id="email" type="text" placeholder="Email">
                  <p class="text-red-500 text-xs italic">{{ error_msg.email }}</p>
                </div>
              </div>
              
              <div class="flex flex-wrap  mb-4">
                <div class="w-full px-3">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="address">
                    Address
                  </label>
                  <input v-model="form.address" class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-green-400" id="address" type="text" placeholder="Address">
                  <p class="text-red-500 text-xs italic">{{ error_msg.address }}</p>
                </div>
              </div>
                
                <!--Footer-->
                <div class="flex justify-end pt-2">
                  <button @click="clearForm" type="button" class="p-3 rounded-lg bg-red-400 hover:bg-red-600 text-white font-bold mr-2">Clear</button>
                  <button type="submit" class="p-3 rounded-lg bg-yellow-400 hover:bg-yellow-600 text-white font-bold mr-2">Update</button>
                </div>
            </form>
          </div>
          
        </div>
      </div>
    </div>
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import CustomerAdd from '@/components/customer/CustomerAdd.vue'
import api from '@/services/auth'
import { useSweetalertTopEnd } from '@/js/sweetalert'
import "@ocrv/vue-tailwind-pagination/dist/style.css"
import VueTailwindPagination from '@ocrv/vue-tailwind-pagination'
import swal from 'sweetalert2'

export default {
    name:"Customer",
    components:{
        CustomerAdd,
        VueTailwindPagination
    },
    setup(){
        const customers = ref([]);
        const keyword = ref("");
        const total = ref(0);
        const current_page = ref(1);
        const per_page = ref(0);
        const modal_edit_customer = ref(false);
        const form = reactive({
            id : '',
            full_name : '',
            tax_no : '',
            phone : '',
            email : '',
            address : '',
            })
        const error_msg = reactive({
            full_name : '',
            tax_no : '',
            phone : '',
            email : '',
            address : '',
        })
        const Swal = swal

        const getCustomers = (page,keyword_param) => { 
            api.get(`api/customers?page=${page}&keyword=${keyword_param}`).then((response)=>{
                console.log(response.data)
                let success_response = response.data;
                customers.value = success_response.data.data
                total.value = success_response.data.total
                current_page.value = success_response.data.current_page
                per_page.value = success_response.data.per_page

            }).catch( error => {
                let error_response = error.response.data;
                useSweetalertTopEnd(1500, 'error', error_response.message)
            });
        }

        onMounted(() => {
            getCustomers(current_page.value, keyword.value);
        });


        function onPageClick(event){
            current_page.value = event

            getCustomers(current_page.value, keyword.value);
        }

        function openModalEditCustomer(id){
            modal_edit_customer.value = true
            getCustomerById(id)
        }

        function getCustomerById(id){
            api.get(`api/customers/${id}`).then((response)=>{
                // console.log(response.data)
                let success_response = response.data;
                form.id = id
                form.full_name = success_response.data.full_name
                form.tax_no = success_response.data.tax_no
                form.phone = success_response.data.phone
                form.email = success_response.data.email
                form.address = success_response.data.address
                // console.log(form.full_name);

            }).catch( error => {
                let error_response = error.response.data;
                useSweetalertTopEnd(1500, 'error', error_response.message)
            });
        }
        function onSubmitEdit(id){
            api.put(`api/customers/${id}`, form).then(()=>{
                 getCustomers(current_page.value, keyword.value);
                 modal_edit_customer.value = false
                // console.log(form.full_name);

            }).catch( error => {
                let error_response = error.response.data;
                useSweetalertTopEnd(1500, 'error', error_response.message)
            });
        }
        function deleteCustomer(id){
            Swal.fire({
                title: 'Do you want to delete?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    api.delete(`api/customers/${id}`).then(()=>{
                        getCustomers(current_page.value, keyword.value);
                        modal_edit_customer.value = false
                        // console.log(form.full_name);

                    }).catch( error => {
                        let error_response = error.response.data;
                        useSweetalertTopEnd(1500, 'error', error_response.message)
                    });
                }
            })
        }

        function submitSearchForm(){
            console.log(keyword.value);
            getCustomers(1, keyword.value);
        }
        function resetSearchForm(){
            keyword.value = ""
            getCustomers(1, keyword.value);
        }


        return { 
                customers, 
                keyword, 
                getCustomers,
                total,
                current_page,
                per_page,
                onPageClick,
                form,
                error_msg,
                modal_edit_customer,
                openModalEditCustomer,
                getCustomerById,
                onSubmitEdit,
                deleteCustomer,
                submitSearchForm,
                resetSearchForm
            }
  }
  
}
</script>