const state = () => ({
    payment_type_lists: [
        {id:1, message:"เงินสด/CASH", label:"cash", topic_option:"", option:[], is_order_no:false},
        {id:2, message:"โอน/DEPOSIT", label:"deposit", topic_option:"ประเภทการโอน", option:[{label:"debit", message:'Debit'}, {label:'qr_code', message:'QR Code'}], is_order_no:false},
        {id:3, message:"เช็ค/CHEQUE", label:"cheque", topic_option:"", option:[], is_order_no:false},
        {id:4, message:"บัตรเครดิต/CREDIT CARD", label:"credit", topic_option:"ธนาคารของเครื่องรูดบัตร", option:[{label:'kbank', message:'K Bank'}, {label:'scb', message:'SCB'}, {label:'krung_sri', message:'KrungSri'}, {label:'ktc', message:'KTC'}, {label:'bbl', message:'BBL'}, {label:'mpos', message:'M POS'}, {label:'other', message:'Other'}], is_order_no:false},
        {id:5, message:"Online", label:"online", topic_option:"Platform", option:[{label:'shopee', message:'Shopee'}, {label:'lazada', message:'Lazada'}, {label:'jdcentral', message:'JDCentral'}, {label:'nocnoc', message:'NocNoc'}, {label:'other_x_com', message:'Other X-com'}], is_order_no:true},
    ],

})
const getters = {
}
  
const actions = {
}
  
const mutations = {
}
  
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}