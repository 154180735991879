<template>
  <div class="flex flex-col w-full p-5 bg-white rounded-2xl gap-4">
    <div class="flex items-center justify-between">
      <div
        class="text-md font-semibold text-gray-700 md:text-xl dark:text-gray-200"
      >
        Summary
      </div>
    </div>
    <div class="flex items-center justify-between">
      <div class="text-gray-700 dark:text-gray-200">
        Sub Total
      </div>
      <div class="text-black dark:text-gray-200">
        {{ useFormatNumber(form.total_price_sum_discount) }}
      </div>
    </div>
    <div class="flex items-center justify-between">
      <div class="text-gray-700 dark:text-gray-200">
        Discount
      </div>
      <div class="text-black dark:text-gray-200">
        {{ useFormatNumber(form.discount_baht) }}
      </div>
    </div>
    <div class="flex items-center justify-between">
      <div class="text-gray-700 dark:text-gray-200">
        Vat ( {{ useFormatNumber(form.vat) }} % )
      </div>
      <div class="text-black dark:text-gray-200">
        {{ useFormatNumber(form.vat_price) }}
      </div>
    </div>
    <div class="flex items-center justify-between">
      <div class="text-gray-700 dark:text-gray-200">
        Grand Total
      </div>
      <div class="text-black dark:text-gray-200">
        {{ useFormatNumber(form.grand_total) }}
      </div>
    </div>
    <div class="flex items-center justify-between">
      <div
        class="text-gray-700 dark:text-gray-200 cursor-pointer hover:text-blue-300"
        @click="onFullPay"
      >
        Total Pay
      </div>
      <div class="text-black dark:text-gray-200">
        <input
          v-model="form.total_pay"
          @change="onChangePay"
          type="number"
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-right"
        />
      </div>
    </div>
    <div class="flex items-center justify-between">
      <div class="text-gray-700 dark:text-gray-200">
        Changes
      </div>
      <div :class="!istotalPay?'text-black dark:text-gray-200':'text-red-600 dark:text-red-600'">
        {{ useFormatNumber(changesTotal) }}
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useFormatNumber } from "@/js/format";

export default {
  name: "Summary",
  emits:['onCheckButton'],
  setup(props, {emit}) {
    const istotalPay = ref(false);
    const changesTotal = ref(0);
    const store = useStore();
    const onFullPay = () => {
      store.state.manualOrder.form.total_pay =
        store.state.manualOrder.form.grand_total;
    };

    const onChangePay = (e) => {
      changesTotal.value = e.target.value - store.state.manualOrder.form.grand_total;
      istotalPay.value = e.target.value < store.state.manualOrder.form.grand_total
      emit('onCheckButton', istotalPay.value)
    };

    return {
      form: computed(() => store.state.manualOrder.form),
      useFormatNumber,
      onFullPay,
      onChangePay,
      istotalPay,
      changesTotal
    };
  },
};
</script>
