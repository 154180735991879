import { ref } from "vue";
import api from '@/services/auth'
import { useSweetalertTopEnd } from '@/js/sweetalert'

export function useGetInvoices(){
    const invoices = ref([]);
    const loading = ref(false);
    const total = ref(1);
    const current_page = ref(1);
    const per_page = ref(0);
    const warehouse_location_id = localStorage.getItem('warehouse_location_id')
    
    const getPosOrderInvoices = (page, keyword='') => { 
        api.get(`api/pos_order_invoices?page=${page}&keyword=${keyword}&warehouse_location_id=${warehouse_location_id}`).then((response)=>{
            let success_response = response.data;
            loading.value = true
            invoices.value = success_response.data.data
            total.value = success_response.data.total
            current_page.value = success_response.data.current_page
            per_page.value = success_response.data.per_page

        }).catch( error => {
            let error_response = error.response.data;
            useSweetalertTopEnd(1500, 'error', error_response.message)
        });
    }
    return { invoices, loading, total, current_page, per_page, getPosOrderInvoices };
}

export function useGetInvoiceByInvoiceNo(){
    const tax_invoice = ref([]);
    const loading = ref(false);

    const getInvoiceByInvoiceNo = async (tax_invoice_no) => { 
        await api.get(`api/pos_order_invoices/${tax_invoice_no}`).then((response)=>{
            let success_response = response.data;
            tax_invoice.value = success_response.data
            loading.value = true
        }).catch( error => {
            let error_response = error.response.data;
            useSweetalertTopEnd(1500, 'error', error_response.message)
        });
    }
    return { tax_invoice, getInvoiceByInvoiceNo, loading };
}
export function printTaxInvoice(tax_invoice_no){
    window.open("/invoices/"+tax_invoice_no, '_blank');
}
