<template>
    <!-- right sidebar -->
    <div class="w-full flex flex-col h-full pr-4 pl-2 ">
      <div class="bg-white rounded-3xl flex flex-col h-full shadow">
          <div class="h-16 text-center flex justify-center pt-4">
            <div class="pl-8 text-left text-lg  relative">
              <!-- cart icon -->
              <i class="fas fa-shopping-cart text-2xl"></i>
              <div class="text-center absolute bg-green-500 text-white w-5 h-5 text-xs p-0 leading-5 rounded-full -right-2 top-3" >{{ sumQuantity }}</div>
            </div>
            <div class="flex-grow px-8 text-right text-lg  relative">
              <!-- trash button -->
              <!-- <button @click="clearCart()" class="px-2 py-1 rounded-md text-white border-white border border-transparent bg-red-500 focus:outline-none text-md hover:bg-red-700">
                  <i class="far fa-trash-alt"></i>
              </button> -->
            </div>
          </div>
          <!-- input barcode -->
          <!-- <div class="flex px-2 flex-row relative mb-2">
            <div class="absolute left-6 top-2 px-5 py-2 rounded-sm h-8 w-8 flex items-center justify-center bg-black text-white">
              <i class="fas fa-barcode"></i>
            </div>
            <input type="text" class="bg-white rounded-3xl shadow text-lg full w-full h-12 py-4 pl-16 transition-shadow focus:shadow-md focus:outline-none" placeholder="Scan Barcode"/>
          </div> -->
          <!-- empty cart -->
          <div v-if="carts.length==0" class="flex-1 w-full p-4 opacity-25 select-none flex flex-col flex-wrap content-center justify-center">
            <i class="fas fa-shopping-cart text-4xl text-center"></i>
            <p>
              CART EMPTY
            </p>
          </div>

          <!-- cart items -->
          <div v-if="carts.length>0" class="flex-1 flex flex-col overflow-auto">

            <div class="flex-1 w-full px-4 overflow-auto">
              <div v-for="cart in carts.slice().reverse()" :key="cart.id" class="select-none mb-3 bg-gray-50 rounded-lg w-full text-gray-700 py-2 px-2 flex justify-center">
                <img :src="cart.photo" alt="" class="rounded-lg h-10 w-10 bg-white shadow mr-2">
                <div class="flex-grow">
                  <h5 class="text-sm">{{ cart.name }}</h5>
                  <p class="text-xs block" v-if="cart.serial_status">S/N: {{ cart.serial }}</p>
                  <p class="text-xs block" v-if="!cart.serial_status">Shelf: {{ cart.shelved_address }}</p>
                  <p class="text-xs block">
                    <span v-if="cart.discount_baht == 0">฿ {{ formatNumber(cart.price*cart.quantity) }}</span>
                    <span v-if="cart.discount_baht > 0">฿  {{ formatNumber((cart.price*cart.quantity)-cart.discount_baht) }} ( {{ formatNumber(cart.price*cart.quantity) }} )</span>
                  </p>
                </div>
                <div class="py-1 self-center mr-4">
                  <p class="text-center flex justify-center">{{ cart.quantity }}</p>
                  <!-- <div class="w-28 grid grid-cols-3 gap-2 items-center">
                    <button @click="subQty(cart, 1)" class="rounded-lg text-center py-1 text-white bg-gray-600 hover:bg-gray-700 focus:outline-none">-</button>
                    <p class="text-center flex justify-center">{{ cart.quantity }}</p>
                    <button @click="addQty(cart, 1)" class="rounded-lg text-center py-1 text-white bg-gray-600 hover:bg-gray-700 focus:outline-none">+</button>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
          <!-- end of cart items -->

          <!-- payment info -->
          <div class="select-none h-auto w-full text-center pt-3 pb-4 px-4">
            <div class="mb-3 text-gray-700 px-3 pt-2 pb-3 rounded-lg bg-green-50">
              <div class="flex text-lg font-semibold">
                <div @click="addCash" class="flex-grow text-left hover:text-blue-300 cursor-pointer">TOTAL</div>
                <div class="flex text-right">
                  <div v-if="netPrice==sumPrice" class="text-right w-full">{{ formatNumber(sumPrice) }}</div>
                  <div v-if="netPrice!=sumPrice" class="text-right w-full">{{ formatNumber(netPrice) }} ( {{ formatNumber(sumPrice) }})</div>
                </div>
              </div>
            </div>
            <div class="mb-3 text-gray-700 px-3 pt-2 pb-3 rounded-lg bg-yellow-50">
              <div class="flex text-lg font-semibold">
                <div class="flex-grow text-left">DISCOUNT</div>
                <div class="flex text-right">
                  <!-- <div class="flex flex-row relative">
                    <div class="absolute flex items-center justify-center bg-gray-200 px-2 rounded-l-lg">
                      <p class="font-semibold">%</p>
                    </div>
                    <input v-model="discount_percent" @input="discountPercentToBath" type="number" class="w-36 text-right bg-white shadow rounded-lg focus:bg-white focus:shadow-lg px-2 focus:outline-none" />
                  </div> -->
                  <p class="">~ {{ formatNumber(sumDiscount) }}</p>
                  <!-- <div class="flex flex-row relative">
                    <div class="absolute flex items-center justify-center bg-gray-200 px-2 rounded-l-lg">
                      <p class="font-semibold">฿</p>
                    </div>
                    <input v-model="discount_bath" @input="discountBathToPercent" type="number" class="w-36 text-right bg-white shadow rounded-lg focus:bg-white focus:shadow-lg px-2 focus:outline-none" />
                  </div> -->
                </div>
              </div>
            </div>
            <div class="mb-3 text-gray-700 px-3 pt-2 pb-3 rounded-lg bg-gray-50">
              <div class="flex text-lg font-semibold">
                <div class="flex-grow text-left">CASH</div>
                <div class="flex text-right">
                  <div class="mr-2"></div>
                  <input v-model="cash" type="number" class="w-32 text-right bg-white shadow rounded-lg focus:bg-white focus:shadow-lg px-2 focus:outline-none">
                </div>
              </div>
              <hr class="my-2">
              <div class="grid grid-cols-3 gap-2 mt-2">
                <button @click="increaseCash(20)" class="bg-white rounded-lg shadow hover:shadow-lg focus:outline-none inline-block px-2 py-1 text-sm">+<span>20</span></button>
                <button @click="increaseCash(50)" class="bg-white rounded-lg shadow hover:shadow-lg focus:outline-none inline-block px-2 py-1 text-sm">+<span>50</span></button>
                <button @click="increaseCash(100)" class="bg-white rounded-lg shadow hover:shadow-lg focus:outline-none inline-block px-2 py-1 text-sm">+<span>100</span></button>
                <button @click="increaseCash(500)" class="bg-white rounded-lg shadow hover:shadow-lg focus:outline-none inline-block px-2 py-1 text-sm">+<span>500</span></button>
                <button @click="increaseCash(1000)" class="bg-white rounded-lg shadow hover:shadow-lg focus:outline-none inline-block px-2 py-1 text-sm">+<span>1,000</span></button>
                <button @click="clearCash()" class="bg-red-500 rounded-lg shadow hover:shadow-lg focus:outline-none inline-block px-2 py-1 text-sm text-white transition-colors duration-150 border border-red-300"><i class="fas fa-sync mr-2"></i><span>Clear</span></button>
              </div>
            </div>
            
            <div v-if="carts.length>0">
              <div x-show="change >= 0" class="flex mb-3 text-lg font-semibol rounded-lg py-2 px-3" :class="calChange>=0 ? 'bg-green-50 text-green-800' : 'bg-pink-100 text-pink-600'">
                <div class="">CHANGE</div>
                <div class="text-right flex-grow">{{formatNumber(calChange)}}</div>
              </div>
            </div>
            <div class="flex justify-around">
              <button @click="save(2)" class="text-white rounded-2xl text-lg py-3 mr-2 w-full focus:outline-none" :class="calChange>=0 && carts.length>0 ? 'bg-green-400 hover:bg-green-500' : 'bg-gray-300 cursor-not-allowed'" :disabled="calChange<0 || carts.length==0">
                CHECKOUT <br> 
                <span class="text-xs">{{ order_no }}</span>
              </button>
              <router-link to="/orders" class="flex justify-center text-white rounded-2xl text-lg w-4/12 py-3 focus:outline-none bg-yellow-400 hover:bg-yellow-500"><div class="self-center">Back</div></router-link>
            </div>
          </div>
          <!-- end of payment info -->
      </div>
    </div>
    <!--Modal Shelf-->
    <div v-if="modal_shelf" class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center">
      <div class="modal-overlay absolute w-full h-full backdrop-filter backdrop-blur-sm backdrop-brightness-50"></div>
      
      <div class="modal-container bg-white lg:w-1/4 md:w-1/2 w-1/3 mx-auto rounded shadow-lg z-50">

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="modal-content py-4 text-left px-6">
          <!--Title-->
          <div class="flex justify-between items-center pb-3">
            <p class="font-bold">Please select shelf</p>
            <div @click="modal_shelf = false" class="modal-close cursor-pointer z-50">
              <i class="fas fa-times"></i>
            </div>
          </div>
          <!-- <hr class="mb-2"> -->
          <!--Body-->
          <div class="flex flex-row  mb-2">
            <Multiselect 
            v-model="shelf_id"
            :options="shelf_options"
            searchable
            @clear="shelf_id=''"
            />
          </div> 
          <!--Footer-->
          <div class="flex justify-end pt-2">
            <button @click="modal_shelf = false" class="bg-red-400 hover:bg-red-600 p-3 rounded-lg text-white font-bold mr-2">Cancel</button>
            <button @click="saveBarcode" class="bg-green-400 hover:bg-green-600 p-3 rounded-lg text-white font-bold mr-2">Select</button>
          </div>
          
        </div>
      </div>
    </div>
    <!--Modal Shelf-->
    
    <!--Modal Receipt-->
    <div v-if="modal_receipt" class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center overflow-hidden" @contextmenu="stop_click_right($event)">
      <!-- <div @click="modal_receipt = false" class="modal-overlay absolute w-full h-full backdrop-filter backdrop-blur-sm backdrop-brightness-50"></div> -->
      <div class="modal-overlay absolute w-full h-full backdrop-filter backdrop-blur-sm backdrop-brightness-50"></div>
      
      <div class="modal-container bg-white lg:w-1/3 w-1/2 mx-auto rounded-2xl shadow-lg z-50 max-h-full overflow-y-auto">

        <div class="">
          <div @click="modal_receipt = false" class="modal-close cursor-pointer z-50 float-right mr-3 mt-2 text-yellow-600">
            <button class="border bg-yellow-400 text-white px-3 py-1 text-xl rounded-2xl hover:bg-yellow-500 focus:outline-none"><i class="fas fa-edit"></i></button>
          </div>
          <!--Body-->
          <Receipt :order_no="order_no"/>

          <div class="p-4 w-full flex justify-center">
            <button @click="printReceipt" class="text-white bg-green-400 hover:bg-green-600 text-lg py-2 rounded-2xl w-full focus:outline-none mx-2"><i class="fas fa-print mr-2"></i>Receipt</button>
            <button @click="openModalCustomer" class="text-yellow-600 text-lg py-2 rounded-2xl w-1/2 focus:outline-none border border-yellow-600 mx-2 hover:bg-yellow-600 hover:text-white" ><i class="fas fa-file-invoice-dollar mr-2"></i>TAX</button>
          </div>
          
        </div>
      </div>
    </div>
    
    <!--Modal discount-->
    <div v-if="modal_discount" class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center">
      <div class="modal-overlay absolute w-full h-full backdrop-filter backdrop-blur-sm backdrop-brightness-50"></div>
      
      <div class="modal-container bg-white lg:w-1/3 md:w-1/2 w-1/3 mx-auto rounded shadow-lg z-50">

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="modal-content py-4 text-left px-6">
          <!--Title-->
          <div class="flex justify-between items-center pb-3">
            <p class="font-bold">Price and Discount</p>
            <div @click="modal_discount = false" class="modal-close cursor-pointer z-50">
              <i class="fas fa-times"></i>
            </div>
          </div>
          <hr>
          <!-- <hr class="mb-2"> -->
          <!--Body-->
          <div class="flex w-full overflow-auto my-3">
            <img :src="cart_edit_price.photo" alt="" class="rounded-lg h-20 w-20 bg-white shadow mx-3">
            <div class="flex-grow">
              <h5 class="text-sm">{{ cart_edit_price.name }}</h5>
              <p class="text-xs block" v-if="cart_edit_price.serial_status">S/N: {{ cart_edit_price.serial }}</p>
              <p class="text-xs block" v-if="!cart_edit_price.serial_status">Shelf: {{ cart_edit_price.shelved_address }}</p>
              <p class="text-xs block">
                <span>{{ formatNumber(cart_edit_price.price) }} Baht/Psc.</span>
              </p>
              <p class="text-xs block">
                <span>{{ formatNumber(cart_edit_price.quantity) }} Psc.</span>
              </p>
              <p class="text-xs block">
                <span v-if="cart_edit_price.discount_baht == 0">Total {{ formatNumber(cart_edit_price.price*cart_edit_price.quantity) }} Baht</span>
                <span v-if="cart_edit_price.discount_baht > 0">Total  {{ formatNumber((cart_edit_price.price*cart_edit_price.quantity)-cart_edit_price.discount_baht) }} ( {{ formatNumber(cart_edit_price.price*cart_edit_price.quantity) }} ) Baht</span>
              </p>
            </div>
          </div>
          <div class="flex flex-wrap my-3">
              <div class="w-full px-3">
                <label class="block tracking-wide text-gray-700 text-xs font-bold mb-2" for="price_edit">
                    Price (Baht/Psc)
                </label>
                <input @input="discountBathToPercentCart" v-model="cart_edit_price.price" class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-green-400" id="price_edit" type="number" placeholder="Price / Pcs.">
                <p class="text-red-500 text-xs italic"></p>
              </div>
          </div>
          <div class="flex flex-wrap mb-3">
              <div class="w-full px-3">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="discount_percent_edit">
                    Discount (%)
                </label>
                <input @input="discountPercentToBathCart" v-model="cart_edit_price.discount_percent" class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-green-400" id="discount_percent_edit" type="number" placeholder="Price / Pcs.">
                <p class="text-red-500 text-xs italic"></p>
              </div>
          </div>
          <div class="flex flex-wrap mb-3">
              <div class="w-full px-3">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="discount_baht">
                    Discount (฿)
                </label>
                <input @input="discountBathToPercentCart" v-model="cart_edit_price.discount_baht" class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-green-400" id="discount_baht" type="number" placeholder="Price / Pcs.">
                <p class="text-red-500 text-xs italic"></p>
              </div>
          </div>
          <!--Footer-->
          <div class="flex justify-end pt-2">
            <button @click="modal_discount = false" class="bg-red-400 hover:bg-red-600 p-3 rounded-lg text-white font-bold mr-2">Close</button>
            <button @click="saveDiscount" class="bg-green-400 hover:bg-green-600 p-3 rounded-lg text-white font-bold mr-2">Add</button>
          </div>
          
        </div>
      </div>
    </div>
    <!--Modal discount-->
    <!--Modal payment_type-->
    <div v-if="modal_payment_type" class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center">
      <div class="modal-overlay absolute w-full h-full backdrop-filter backdrop-blur-sm backdrop-brightness-50"></div>
      
      <div class="modal-container bg-white lg:w-1/2 md:w-1/2 w-1/2 mx-auto rounded shadow-lg z-50">

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="modal-content py-4 text-left px-6">
          <!--Title-->
          <div class="flex justify-between items-center pb-3">
            <p class="font-bold">Payment Type</p>
            <div @click="modal_payment_type = false" class="modal-close cursor-pointer z-50">
              <i class="fas fa-times"></i>
            </div>
          </div>
          <hr>
          <!-- <hr class="mb-2"> -->
          <!--Body-->
          <div class="flex flex-wrap my-3">
              <div class="w-full px-3">
                <span v-for="payment_type_data in payment_type_lists" :key="payment_type_data.id">
                  <input v-model="payment_type" @change="select_payment_type" type="radio" :value="payment_type_data.message" :id="payment_type_data.label" class="cursor-pointer mr-1"/>
                  <label :for="payment_type_data.label" class="tracking-wide text-gray-700 text-xs font-bold cursor-pointer mr-3">{{ payment_type_data.message }}</label>
                </span>
              </div>
          </div>
          <div v-if="payment_type!=null && payment_type_lists.find(item=>item.message==payment_type).option.length > 0" class="flex flex-wrap my-3">
              <div class="w-full px-3">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="payment_note">
                    {{ payment_type_lists.find(item=>item.message==payment_type).topic_option }}
                </label>
                <span v-for="option in payment_type_lists.find(item=>item.message==payment_type).option" :key="option.label">
                  <input v-model="payment_option" type="radio" :value="option.message" :id="option.label" class="cursor-pointer mr-1"/>
                  <label :for="option.label" class="tracking-wide text-gray-700 text-xs font-bold cursor-pointer mr-3">{{ option.message }}</label>
                </span>
              </div>
          </div>
          <div v-if="payment_type_lists.find(item=>item.message==payment_type) ? payment_type_lists.find(item=>item.message==payment_type).is_order_no : false " class="flex flex-wrap my-3">
              <div class="w-full px-3">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="online_order_no">
                    Order no <span class="text-red-600">*</span>
                </label>
                <input v-model="online_order_no" type="text" name="online_order_no" id="online_order_no" cols="20" rows="5" class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-green-400">
              </div>
          </div>
          <div class="flex flex-wrap my-3">
              <div class="w-full px-3">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="payment_note">
                    Note
                </label>
                <textarea v-model="payment_note" name="payment_note" id="payment_note" cols="20" rows="5" class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-green-400"></textarea>
              </div>
          </div>
          <!--Footer-->
          <div class="flex justify-end pt-2">
            <button @click="modal_payment_type = false" class="bg-red-400 hover:bg-red-600 p-3 rounded-lg text-white font-bold mr-2">Close</button>
            <button @click="save(2,'savePayment')" class="bg-green-400 hover:bg-green-600 p-3 rounded-lg text-white font-bold mr-2">Save</button>
          </div>
          
        </div>
      </div>
    </div>
    <!--Modal payment_type-->

    <!--Modal Tax-->
    <span v-if="modal_form_tax">
      <TaxModal :modal_form_tax_props="modal_form_tax" :order_no_props="order_no" @closeModalTax="modal_form_tax=false" @saveTax="printTaxInvoice"/>
    </span>
    <!--Modal Tax-->
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import axios from "axios";
import Swal from 'sweetalert2'
import Receipt from '@/components/Receipt.vue'
import TaxModal from '@/components/TaxModal.vue'
import api from '@/services/auth'
import { useSweetalertTopEnd, useSweetalertError } from '@/js/sweetalert'
import { useFormatNumber } from '@/js/format'
import { useGetOrderByOrderNo, alert_is_order_management } from '@/js/pos_order'
import Multiselect from '@vueform/multiselect'

export default {
  name:"Pos",
  components:{
    Receipt,
    TaxModal,
    Multiselect
  },
  setup(){
    const keyword = ref("");
    const keyword_result = ref("");
    const products = ref([]);
    const cash = ref(0);
    const discount_bath = ref(0);
    const discount_percent = ref(0);
    const carts = ref([]);
    const modal_shelf = ref(false);
    const shelf_options = ref([])
    const modal_receipt = ref(false);
    const modal_discount = ref(false);
    const modal_payment_type = ref(false);
    const order_no = ref("");
    const router = useRouter();
    const route = useRoute()
    const formatNumber = useFormatNumber;
    const customer_id = ref(0)
    const pos_order_status_id = ref()
    const { order, line_items, getOrderByOrderNo } = useGetOrderByOrderNo();
    const modal_form_tax = ref(false)
    const pos_machine_id = localStorage.getItem('pos_machine_id')
    const warehouse_location_id = localStorage.getItem('warehouse_location_id')
    const barcode = ref("");
    const serial = ref("");
    const shelf_id = ref("");
    const shelf_barcode = ref("");
    const cart_edit_price = ref("");
    const payment_type = ref("เงินสด/CASH");
    const payment_option = ref("เงินสด/CASH");
    const online_order_no = ref("");
    const payment_note = ref("");
    const is_order_management = ref(null);
    const input_search = ref("");
    const payment_type_lists = ref([
      {id:1, message:"เงินสด/CASH", label:"cash", topic_option:"", option:[], is_order_no:false},
      {id:2, message:"โอน/DEPOSIT", label:"deposit", topic_option:"ประเภทการโอน", option:[{label:"debit", message:'Debit'}, {label:'qr_code', message:'QR Code'}], is_order_no:false},
      {id:3, message:"เช็ค/CHEQUE", label:"cheque", topic_option:"", option:[], is_order_no:false},
      {id:4, message:"บัตรเครดิต/CREDIT CARD", label:"credit", topic_option:"ธนาคารของเครื่องรูดบัตร", option:[{label:'kbank', message:'K Bank'}, {label:'scb', message:'SCB'}, {label:'krung_sri', message:'KrungSri'}, {label:'ktc', message:'KTC'}, {label:'bbl', message:'BBL'}, {label:'mpos', message:'M POS'}, {label:'other', message:'Other'}], is_order_no:false},
      {id:5, message:"Online", label:"online", topic_option:"Platform", option:[{label:'shopee', message:'Shopee'}, {label:'lazada', message:'Lazada'}, {label:'jdcentral', message:'JDCentral'}, {label:'nocnoc', message:'NocNoc'}, {label:'other_x_com', message:'Other X-com'}], is_order_no:true},
    ]);


    const getProducts = (warehouse_location_id) => { 
      console.log(warehouse_location_id);
        // api.get(`api/pos_products`, { 
        //   params :{
        //     warehouse_location_id,
        //     keyword: btoa(keyword.value),
        //     barcode: btoa(barcode.value),
        //     serial: btoa(serial.value),
        //     group_price_id: process.env.VUE_APP_ERP_GROUP_PRICE_ID
        //   }
        // }).then((response)=>{
        //     let success_response = response.data;
        //     if(success_response.status==0){
        //       if(success_response.message=="cart"){
        //         products.value = success_response.data
        //         addToCart(products.value[0])
        //         resetSearchForm()
        //       }
        //       if(success_response.message=="display"){
        //         products.value = success_response.data
        //         barcode.value = keyword.value
        //       }
        //       if(success_response.message=="shelf"){
        //         // alert select shelf
        //         shelf_options.value = [];
        //         success_response.data.forEach(function(item){
        //           let arr = {
        //             value : item.shelf_id,
        //             label : `${item.shelved_address} : ${item.quantity} Pcs.`
        //           };
        //           shelf_options.value.push(arr)
        //         })
        //         modal_shelf.value = true
        //         shelf_barcode.value = keyword.value
                
        //       }
              
        //     }
        //     if(success_response.status==1){
        //       if(barcode.value==""){
        //         resetSearchForm()
        //       }
        //       Swal.fire({
        //         icon: 'error',
        //         text: success_response.message,
        //         confirmButtonColor: '#3085d6',
        //         confirmButtonText:'OK',
        //       });
        //     }
        //     keyword.value = "";

        // }).catch( error => {
        //     let error_response = error.response.data;
        //     useSweetalertTopEnd(1500, 'error', error_response.message)
        // });
    }

    onMounted(() => {
        getProducts(warehouse_location_id);
        if(route.query.order_no_query){
          order_no.value = route.query.order_no_query
          getOrderByOrderNo(order_no.value).then(()=>{
            cash.value = parseInt(order.value.total_pay)
            discount_bath.value = order.value.discount_bath
            discount_percent.value = order.value.discount_percent
            payment_type.value = order.value.payment_type
            payment_option.value = order.value.payment_option
            online_order_no.value = order.value.online_order_no
            payment_note.value = order.value.payment_note
            is_order_management.value = order.value.is_order_management
            carts.value = [...line_items.value];
          })
        }
    });

    function saveBarcode(){
      if(order_no.value && is_order_management.value==1){
        alert_is_order_management()
        return false
      }
      if(shelf_id.value=="" || shelf_id.value==null){
        alert("Please select shelf");
        return false
      }
      api.get(`api/pos_shelf_products`, { 
          params :{
            shelf_id: shelf_id.value,
            shelf_barcode: shelf_barcode.value,
            group_price_id: process.env.VUE_APP_ERP_GROUP_PRICE_ID
          }
        }).then((response)=>{
          let success_response = response.data;
          products.value = success_response.data
          if(success_response.status==0){
            if(success_response.message=="cart"){
              addToCart(products.value[0])
              resetSearchForm()
            }
          }
          modal_shelf.value = false
          shelf_id.value = ""
          shelf_barcode.value = ""
      }).catch( error => {
          let error_response = error.response.data;
          useSweetalertTopEnd(1500, 'error', error_response.message)
      });
    }
    function submitSearchForm(){
        if(order_no.value && is_order_management.value==1){
          alert_is_order_management()
          return false
        }
        if(!keyword.value){
          return false;
        }
        if(barcode.value){
          serial.value = keyword.value;
          let index_product = products.value.findIndex(item => item.serial === serial.value);
          if(index_product >= 0){
            addToCart(products.value[index_product]);
            keyword.value = ""
            resetSearchForm()
          }else{
            getProducts(warehouse_location_id);
          }
        }else{
          // placeholder_search.value = "Scan Serial Number";
          // keyword_result.value = keyword.value;
          getProducts(warehouse_location_id);
        }
    }
    function addToCart(product){
      if(order_no.value && is_order_management.value==1){
        alert_is_order_management()
        return false
      }
      if(product.serial_status==1){
        let index_cart = carts.value.findIndex(item => item.product_id === product.product_id);
        if(index_cart >= 0){
          Swal.fire({
            icon: 'error',
            title: product.serial,
            text: 'Serial number already exist!',
            confirmButtonColor: '#3085d6',
            confirmButtonText:'OK',
          });
        }else{
            let discount_baht = 0;
            let discount_percent = 0;
            let index_cart = carts.value.findIndex(item => item.model_id === product.model_id);
            if(index_cart >= 0){
              product.price = carts.value[index_cart].price
              discount_baht = carts.value[index_cart].discount_baht
              discount_percent = carts.value[index_cart].discount_percent
            }
            carts.value.push({
                model_shelf:product.model_shelf,
                product_id:product.product_id,
                model_id:product.model_id,
                serial_status:product.serial_status,
                serial:product.serial,
                warehouse_shelf_id:product.warehouse_shelf_id,
                shelved_address:product.shelved_address,
                name:product.name,
                barcode:product.barcode,
                price:product.price,
                discount_baht:discount_baht,
                discount_percent:discount_percent,
                quantity:1,
                stock_quantity:product.stock_quantity,
                photo:product.photo,
            })
            resetSearchForm()
        }
      }
      if(product.serial_status==null){
        let index_cart = carts.value.findIndex(item => item.model_shelf === product.model_shelf);
        if(index_cart >= 0){
          if((carts.value[index_cart].quantity+1) > product.stock_quantity){
              Swal.fire({
                icon: 'error',
                title: 'stock',
                text: `${product.barcode} have ${product.stock_quantity} Psc. in shelf ${product.shelved_address}`,
                confirmButtonColor: '#3085d6',
                confirmButtonText:'OK',
              });
          }else{
            carts.value[index_cart].quantity += 1;
          }
        }else{
          if(product.stock_quantity==0){
            Swal.fire({
              icon: 'error',
              title: 'stock',
              text: `${product.barcode} have ${product.stock_quantity} Psc. in shelf ${product.shelved_address}`,
              confirmButtonColor: '#3085d6',
              confirmButtonText:'OK',
            });
          }else{
            carts.value.push({
                model_shelf:product.model_shelf,
                product_id:product.product_id,
                model_id:product.model_id,
                serial_status:product.serial_status,
                serial:product.serial,
                warehouse_shelf_id:product.warehouse_shelf_id,
                shelved_address:product.shelved_address,
                name:product.name,
                barcode:product.barcode,
                price:product.price,
                discount_baht:0,
                discount_percent:0,
                quantity:1,
                stock_quantity:product.stock_quantity,
                photo:product.photo,
            })
          }
        }
      }
      input_search_focus();
    }
    const sumQuantity = computed(() => {
        let sumQuantity = 0;
        carts.value.forEach(item => sumQuantity+=item.quantity);
        return sumQuantity;
    })
    const sumPrice = computed(() => {
        let sumPrice = 0;
        carts.value.forEach(item => sumPrice+=(item.quantity*item.price));
        return sumPrice;
    })
    const sumDiscount = computed(() => {
        let sumDiscount = 0;
        carts.value.forEach(item => sumDiscount+=parseFloat(item.discount_baht));
        return sumDiscount;
    })
    function increaseCash(cashInput){
        input_search_focus();
        cash.value += cashInput
    }
    function clearCash(){
        input_search_focus();
        cash.value = 0
    }
    function discountBathToPercentCart(){
      cart_edit_price.value.discount_percent = cart_edit_price.value.price==0 || cart_edit_price.value.price=="" ? 0 : ((cart_edit_price.value.discount_baht * 100)/(cart_edit_price.value.price*cart_edit_price.value.quantity)).toFixed(2)
    }
    function discountPercentToBathCart(){
        cart_edit_price.value.discount_baht = (cart_edit_price.value.discount_percent * (cart_edit_price.value.price*cart_edit_price.value.quantity))/100
    }
    function addDiscount(cart_param){
      if(order_no.value && is_order_management.value==1){
        alert_is_order_management()
        return false
      }
      cart_edit_price.value = Object.assign({}, cart_param);
      modal_discount.value = true
    }
    function saveDiscount(){
      if(order_no.value && is_order_management.value==1){
        alert_is_order_management()
        return false
      }
      if(cart_edit_price.value.price===""){
        alert("Please input price");
        return false;
      }
      if(cart_edit_price.value.serial_status==1){
        let index_cart = carts.value.findIndex(item => item.product_id === cart_edit_price.value.product_id);
        carts.value[index_cart].price = cart_edit_price.value.price;
        carts.value[index_cart].discount_baht = cart_edit_price.value.discount_baht;
        carts.value[index_cart].discount_percent = cart_edit_price.value.discount_percent;
      }
      if(cart_edit_price.value.serial_status==null){
        let index_cart = carts.value.findIndex(item => item.model_shelf === cart_edit_price.value.model_shelf);
        carts.value[index_cart].price = cart_edit_price.value.price;
        carts.value[index_cart].discount_baht = cart_edit_price.value.discount_baht;
        carts.value[index_cart].discount_percent = cart_edit_price.value.discount_percent;
      }
      let results = carts.value.filter(item => item.model_id === cart_edit_price.value.model_id);
      results.forEach(function(item){
        item.price = cart_edit_price.value.price
        if(item.serial_status==1){
          item.discount_baht = cart_edit_price.value.discount_baht
          item.discount_percent = cart_edit_price.value.discount_percent
        }
      })
      modal_discount.value = false
    }

    function addCash(){
      input_search_focus();
      if(confirm("Do you want cash equal to total ? ")){
        cash.value = netPrice.value;
      }
    } 

    const netPrice = computed(() => sumPrice.value - sumDiscount.value)

    const calChange = computed(() => cash.value - (sumPrice.value - sumDiscount.value))

    function addQty(cart, qty){
      if(order_no.value && is_order_management.value==1){
        alert_is_order_management()
        return false
      }
      input_search_focus();
      if(cart.serial_status==1){
        Swal.fire({
          icon: 'error',
          title: 'stock',
          text: 'Product have serial cannot add more quantity',
          confirmButtonColor: '#3085d6',
          confirmButtonText:'OK',
        });
      }else if(cart.serial_status==null){
        if((cart.quantity+1) > cart.stock_quantity){
            Swal.fire({
              icon: 'error',
              title: 'stock',
              text: `${cart.barcode} have ${cart.stock_quantity} Psc. in shelf ${cart.shelved_address}`,
              confirmButtonColor: '#3085d6',
              confirmButtonText:'OK',
            });
        }else{
          cart.quantity += qty;
        }
        
      }
    }
    function subQty(cart, qty){
      if(order_no.value && is_order_management.value==1){
        alert_is_order_management()
        return false
      }
      input_search_focus();
      cart.quantity -= qty;
      if(cart.quantity <= 0){
        let index = carts.value.indexOf(cart);
        carts.value.splice(index,1);
      }
    }

    function clearCart(){
      if(order_no.value && is_order_management.value==1){
        alert_is_order_management()
        return false
      }
      input_search_focus();
      Swal.fire({
        title: 'Do you want to delete?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          carts.value.splice(0);
        }
      })
    }

    function openModalCustomer(){
      // if(order_no.value && is_order_management.value==1){
      //   alert_is_order_management()
      //   return false
      // }
      modal_receipt.value = false
      modal_form_tax.value = true
    }
    function closeModalCustomer(){
      modal_receipt.value = true
    }

    function select_payment_type(){
      online_order_no.value=""
      if(payment_type_lists.value.find(item=>item.message==payment_type.value).option.length > 0){
        payment_option.value = ""
      }else{
        payment_option.value = payment_type.value
      }
    }
    function save(status_id, payment=null){
      pos_order_status_id.value = status_id
      let text_alert =  status_id == 1 ? "save pending" : "checkout" ;

      if(payment==null && status_id==2){
        modal_payment_type.value = true
        return false
      }

      if(payment=="savePayment" && status_id==2 && ((payment_option.value=="" || payment_option.value==null) || (payment_type.value=="" || payment_type.value==null))){
        if(payment_type.value=="" || payment_type.value==null){
          alert(`Please select payment type`)
          return false
        }
        alert(`Please select ${payment_type_lists.value.find(item=>item.message==payment_type.value).topic_option}`)
        return false
      }
      if(payment=="savePayment" && status_id==2 && (payment_type_lists.value.find(item=>item.message==payment_type.value).is_order_no && (online_order_no.value=="" || online_order_no.value==null))){
          alert(`Please input order no`)
          return false
      }
      Swal.fire({
        title: `Do you want to ${text_alert} order ${order_no.value} ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm'
      }).then((result) => {
        if (result.isConfirmed) {
            modal_payment_type.value = false
            resetSearchForm()
            if(!order_no.value){
              api.post('api/pos_orders',{
                pos_order_status_id : status_id,
                total_price : sumPrice.value,
                total_pay : cash.value,
                cart : carts.value,
                discount_bath : sumDiscount.value,
                discount_percent : discount_percent.value,
                pos_machine_id : pos_machine_id,
                warehouse_location_id : warehouse_location_id,
                payment_type : payment_type.value,
                payment_option : payment_option.value,
                online_order_no : online_order_no.value,
                payment_note : payment_note.value,
              }).then((response)=>{
                  getProducts(warehouse_location_id);
                  let success_response = response.data;
                  useSweetalertTopEnd(1500, 'success', success_response.message)
                  if(status_id==1){
                      carts.value.splice(0)
                      cash.value = 0
                      router.replace("/");
                  }
                  if(status_id==2){
                      order_no.value = success_response.data.order_no;
                      modal_receipt.value=true
                  }
    
              }).catch( error => {
                  let error_response = error.response.data;
                  useSweetalertError(error_response.message)
              });
            }
            if(order_no.value){
              api.put(`api/pos_orders/${order_no.value}`,{
                pos_order_status_id : status_id,
                total_price : sumPrice.value,
                total_pay : cash.value,
                cart : carts.value,
                discount_bath : sumDiscount.value,
                discount_percent : discount_percent.value,
                pos_machine_id : pos_machine_id,
                warehouse_location_id : warehouse_location_id,
                payment_type : payment_type.value,
                payment_option : payment_option.value,
                online_order_no : online_order_no.value,
                payment_note : payment_note.value,
              }).then((response)=>{
                  getProducts(warehouse_location_id);
                  let success_response = response.data;
                  useSweetalertTopEnd(1500, 'success', success_response.message)
                  if(status_id==1){
                      carts.value.splice(0)
                      cash.value = 0
                      if(route.query.order_no_query){
                        router.replace("/orders");
                      }
                  }
                  if(status_id==2){
                      order_no.value = success_response.data.order_no;
                      modal_receipt.value=true
                  }
    
              }).catch( error => {
                  let error_response = error.response.data;
                  useSweetalertError(error_response.message)
              });
            }
        
        }
      })
      
    }

    function printReceipt(){
        if(is_order_management.value==1){
            axios.post(`${process.env.VUE_APP_HOST_ORDER_MANAGEMENT}orders/print_short_tax_invoice`,{
              erp_pos_order_id : order.value.id,
              obj_pos_order : order.value,
            }).then((response)=>{
              console.log(response);
              if(route.query.order_no_query){
                router.replace("/orders");
              }
              if(!route.query.order_no_query){
                router.replace("/");
              }
              window.print();
              modal_receipt.value=false
              carts.value.splice(0);
              order_no.value = '';
              payment_type.value = "เงินสด/CASH";
              payment_option.value = "เงินสด/CASH";
              online_order_no.value = "";
              payment_note.value = "";
              cash.value = 0
              is_order_management.value = null;
            }).catch( error => {
                let error_response = error.response.data;
                useSweetalertError("Error from order management"+error_response.error_message)
            });
        }else{
          if(route.query.order_no_query){
            router.replace("/orders");
          }
          if(!route.query.order_no_query){
            router.replace("/");
          }
          window.print();
          modal_receipt.value=false
          carts.value.splice(0);
          order_no.value = '';
          payment_type.value = "เงินสด/CASH";
          payment_option.value = "เงินสด/CASH";
          online_order_no.value = "";
          payment_note.value = "";
          cash.value = 0;
          is_order_management.value = null;
        }
        
    }
    function resetSearchForm(){
        keyword.value = ""
        barcode.value = ""
        serial.value = ""
        getProducts(warehouse_location_id);
        input_search_focus();
    }
    function printTaxInvoice(tax_invoice){
        let routeData = router.resolve({name:'InvoicePdf', params: { tax_invoice_no: tax_invoice.data.tax_invoice_no }})
        window.open(routeData.href, '_blank');
        if(route.query.order_no_query){
          router.replace("/orders");
        }
        
        modal_receipt.value=false
        carts.value.splice(0);
        order_no.value = '';
        customer_id.value = '';
        cash.value = 0
    }
   
    function input_search_focus(){
      // input_search.value.focus();
    }

    function stop_click_right(e){
        e.preventDefault()
    }
    return { 
      keyword,
      keyword_result,
      products, 
      addToCart, 
      carts, 
      sumQuantity, 
      sumPrice, 
      sumDiscount,
      netPrice,
      addCash,
      discount_percent, 
      discount_bath, 
      addDiscount,
      saveDiscount,
      cash, 
      increaseCash, 
      clearCash, 
      calChange, 
      addQty, 
      subQty, 
      clearCart, 
      openModalCustomer,
      closeModalCustomer,
      save,
      modal_shelf,
      shelf_options,
      modal_receipt,
      modal_discount,
      modal_payment_type,
      order_no,
      printReceipt,
      printTaxInvoice,
      resetSearchForm,
      submitSearchForm,
      saveBarcode,
      formatNumber,
      customer_id,
      getOrderByOrderNo,
      modal_form_tax,
      pos_machine_id,
      barcode,
      shelf_id,
      cart_edit_price,
      discountBathToPercentCart,
      discountPercentToBathCart,
      payment_type,
      payment_option,
      online_order_no,
      payment_note,
      input_search,
      payment_type_lists,
      select_payment_type,
      stop_click_right
      }
  }
}
</script>