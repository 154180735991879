<template>
    <div v-if="loading" id="receipt-content-page" class="text-left text-sm p-6 pt-2 print-area receipt">
    <span v-for="index in 2" :key="index">
        <div v-if="index!=1" class="page-break"></div>
        <div class="relative">
            <div id="watermark_void" class="" v-if="order.pos_order_invoice">
                <img src="../../assets/image/void.png" alt="void" class="w-full" id="image_void">
            </div>
            <div id="watermark_cancel" class="" v-if="order.deleted_at !=null">
                <img src="../../assets/image/cancel.png" alt="cancel"  class="w-full" id="image_cancel">
            </div>
            <div class="text-center" :class="index!=1 && 'mt-3'">
              <img src="../../assets/image/stm-no-title.png" alt="STM POS" class="mb-3 w-20 inline-block" id="photo_logo">
              <p class="font-bold text-sm">{{ company.name_th }}</p>
              <p class="text-sm">POS ID {{ order.pos_machine.name }}</p>
              <p class="text-sm">สาขา {{ order.warehouse_location.branch_id }}</p>
              <p class="text-sm">เลขประจำตัวผู้เสียภาษี : {{ order.warehouse_location.tax_no }}</p>
              <p class="text-sm">{{ order.address }}</p>
              <p class="text-sm">โทร {{ company.phone }}</p>
            </div>
            <div class="mt-4 text-center">
              <p class="font-bold text-sm">ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ</p>
            </div>
            <hr class="my-1">
            <p class="text-center text-sm">{{ order_no }}</p>
            <p class="text-center text-sm">Date : {{ moment(order.created_at).format('D/M/yyy h:mm a') }}</p>
            <div>
              <table class="w-full text-sm">
                <tbody>
                    <tr>
                      <td width="120px">Cashier</td>
                      <td>:</td>
                      <td>{{ order.user?.employee?.firstname }} {{ order.user?.employee?.lastname }}</td>
                    </tr>
                    <tr>
                      <td>รหัสลูกค้า</td>
                      <td>:</td>
                      <td class="">-</td>
                    </tr>
                    <tr>
                      <td class="">{{ order.payment_type }}</td>
                      <td v-if="order.payment_type=='Online'">:</td>
                      <td v-if="order.payment_type=='Online'">{{ order.online_order_no }}</td>
                    </tr>
                </tbody>
              </table>
            </div>
            <hr class="my-3">
            <div>
              <table class="w-full text-sm">
                <thead>
                  <tr>
                    <td class="text-center" colspan="4">รายการสินค้า</td>
                  </tr>
                </thead>
                <tbody>
                    <tr v-for="(pos_order_line_item_tax_receipt) in order.pos_order_line_item_tax_receipts" :key="pos_order_line_item_tax_receipt.model_id" class="text-xs">
                      <td class="text-center align-top">{{ pos_order_line_item_tax_receipt.number }}</td>
                      <td v-if="pos_order_line_item_tax_receipt.name.startsWith('s/n :')" colspan="3" class="text-left">
                        <span>{{ pos_order_line_item_tax_receipt.name }}</span>
                      </td>
                      <td v-if="!pos_order_line_item_tax_receipt.name.startsWith('s/n :')" class="text-left">
                        <span>{{ pos_order_line_item_tax_receipt.name }}</span>
                      </td>
                      <td v-if="!pos_order_line_item_tax_receipt.name.startsWith('s/n :')" class="text-center align-top">
                        <p>{{ pos_order_line_item_tax_receipt.quantity }}</p>
                      </td>
                      <td v-if="!pos_order_line_item_tax_receipt.name.startsWith('s/n :')" class="text-right align-top">
                        <p v-if="pos_order_line_item_tax_receipt.total_not_discount!==''">{{ formatNumber(pos_order_line_item_tax_receipt.total_not_discount) }}</p>
                      </td>
                    </tr>
                </tbody>
              </table>
            </div>
            <hr class="my-2">
            <div class="flex text-sm">
              <div class="flex-grow">รวมจำนวนสินค้า</div>
              <div class="text-right">{{ formatNumber(total_product) }}</div>
            </div>
            <div class="flex text-sm">
              <div class="flex-grow">รวมราคาสินค้า</div>
              <div class="text-right">{{ formatNumber(order.total_price) }}</div>
            </div>
            <div class="flex text-sm ">
              <div class="flex-grow">ส่วนลดการค้า</div>
              <div class="text-right">{{ formatNumber(order.discount_bath) }}</div>
            </div>
            <div class="flex text-sm">
              <div class="flex-grow">รวมเป็นเงินทั้งสิ้น</div>
              <div class="text-right">{{ formatNumber(order.grand_total) }}</div>
            </div>
            <div class="flex text-sm">
              <div class="flex-grow">มูลค่าสินค้า</div>
              <div class="text-right">{{ formatNumber(order.price_before_vat) }}</div>
            </div>
            <div class="flex text-sm ">
              <div class="flex-grow font-bold">ภาษีมูลค่าเพิ่ม 7%</div>
              <div class="text-right">{{ formatNumber(order.vat_price) }}</div>
            </div>
            <div class="text-center">
              <p>VAT INCLUDED</p>
              <p>ขอบคุณที่ใช้บริการ</p>
            </div>
        </div>
    </span>
    </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useGetOrderByOrderNo } from '@/js/pos_order'
import { useCompany } from '@/js/company'
import { useFormatNumber, useFormatDate } from '@/js/format'
import moment from 'moment'

export default {
  name: 'Receipt',
  setup(){
    const { order, total_product, getOrderByOrderNo, loading } = useGetOrderByOrderNo();
    const { company, getCompanyById } = useCompany();
    const order_no = ref("");
    const route = useRoute();
    const formatDate = useFormatDate;
    const formatNumber = useFormatNumber;
    
    onMounted( async () => {
        order_no.value = await route.params.order_no;
        await getOrderByOrderNo(route.params.order_no);
        await getCompanyById(1)
        await window.print()
        await window.close()
    });
    return { order, order_no, loading, formatDate, formatNumber, company, moment, total_product }
  }
}
</script>
<style scope>
@page {
    margin: 0;
}
@font-face {
  font-family: myFirstFont;
  src: url(../../assets/font/f25_bank_printer/F25_Bank_Printer_Bold.ttf);
}
@font-face {
  font-family: myFirstFont2;
  src: url(../../assets/font/kravitz/test.TTF);
}
@font-face {
  font-family: TahomaFont;
  src: url(../../assets/font/Tahoma-Font/TAHOMA_0.TTF);
}
#receipt-content-page{
   font-family: myFirstFont;
   width: 80mm;
}
#image_void{
  width: 430px;
}
#watermark_void {
    position: absolute;
    opacity: 1;
    /** 
        Set a position in the page for your image
        This should center it vertically
    **/
    top:   4cm;
    left: 13.5cm;

    /** Your watermark should be behind every content**/
    z-index:  -1000;
    transform: rotate(-90deg);
}
#image_cancel{
  width: 500px;
}
#watermark_cancel {
    position: absolute;
    opacity: 1;
    /** 
        Set a position in the page for your image
        This should center it vertically
    **/
    top:   1cm;
    left: 12.5cm;

    /** Your watermark should be behind every content**/
    z-index:  -1000;
    transform: rotate(-35deg);
}
@media print {
    #image_void{
      width: 90%;
    }
    #watermark_void {
        position: absolute;
        opacity: 1;
        /** 
            Set a position in the page for your image
            This should center it vertically
        **/
        top:   4cm;
        left: 1cm;

        /** Your watermark should be behind every content**/
        z-index:  -1000;
        transform: rotate(-35deg);
    }
    #image_cancel{
      width: 100%;
    }
    #watermark_cancel {
        position: absolute;
        opacity: 1;
        /** 
            Set a position in the page for your image
            This should center it vertically
        **/
        top:   0cm;
        left: 1cm;

        /** Your watermark should be behind every content**/
        z-index:  -1000;
        transform: rotate(-35deg);
    }
    .page-break {page-break-after: always;}
}
</style>