<template>
    <div
        class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center z-50"
    >
        <div
            class="modal-overlay absolute w-full h-full backdrop-filter backdrop-blur-sm backdrop-brightness-50"
            @click="onClose"
        ></div>
        <div
            class="modal-container bg-white w-4/5 md:w-1/2 mx-auto rounded shadow-lg z-50 max-h-full overflow-y-auto"
        >
            <!-- Add margin if you want to see some of the overlay behind the modal-->
            <div class="modal-content py-4 text-left px-3 md:px-6">
                <!--Title-->
                <div class="flex justify-between items-center pb-3">
                    <p class="font-bold">ADD PRODUCT</p>
                    <div class="modal-close cursor-pointer" @click="onClose">
                        <em class="fas fa-times"></em>
                    </div>
                </div>
                <hr class="mb-2" />
                <!--Body-->
                <div>
                    <!-- <form  class="w-full pt-5"> -->
                        <div class="flex flex-wrap mb-3">
                            <div class="w-full px-1 md:px-3">
                                <label
                                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    for="barcode"
                                >
                                    Barcode </label>
                                <input
                                    v-model="form.barcode"
                                    class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-green-400"
                                    id="barcode"
                                    type="text"
                                    placeholder="Barcode"
                                    @keyup.enter="onEnterBarcode"
                                />
                                <p class="text-red-500 text-xs italic"></p>
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-3">
                            <div class="w-full px-1 md:px-3">
                                <label
                                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    for="document_model_name"
                                >
                                    Product Name </label>
                                <input
                                    v-model="form.document_model_name"
                                    class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-green-400"
                                    id="document_model_name"
                                    type="text"
                                    placeholder="Product Name"
                                    ref="input_document_model_name"
                                    required
                                />
                                <p class="text-red-500 text-xs italic"></p>
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-3">
                            <div class="w-full px-1 md:px-3">
                                <label
                                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    for="serial"
                                >
                                    Serial  No.</label>
                                <input
                                    v-model="form.serial"
                                    class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-green-400"
                                    id="serial"
                                    type="text"
                                    placeholder="Serial No."
                                    ref="input_serial"
                                />
                                <p class="text-red-500 text-xs italic"></p>
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-3">
                            <div class="w-full px-1 md:px-3">
                                <label
                                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    for="ppe"
                                >
                                    Price (฿) </label>
                                <input
                                    v-model="form.ppe"
                                    class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-green-400 text-right"
                                    id="ppe"
                                    type="number"
                                    placeholder="Price (฿)"
                                    step="any"
                                    required
                                />
                                <p class="text-red-500 text-xs italic"></p>
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-3">
                            <div class="w-full px-1 md:px-3">
                                <label
                                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    for="quantity"
                                >
                                    Quantity</label>
                                <input
                                    v-model="form.quantity"
                                    class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-green-400 text-right"
                                    id="quantity"
                                    type="number"
                                    placeholder="Quantity"
                                    min="1"
                                    required
                                />
                                <p class="text-red-500 text-xs italic"></p>
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-3">
                            <div class="w-full px-1 md:px-3">
                                <label
                                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    for="discount_baht"
                                >
                                    Discount (฿)</label>
                                <input
                                    v-model="form.discount_baht"
                                    class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-green-400 text-right"
                                    id="discount_baht"
                                    type="number"
                                    placeholder="Discount (฿)"
                                    required
                                />
                                <p class="text-red-500 text-xs italic"></p>
                            </div>
                        </div>
                        <div class="flex flex-wrap mb-3">
                            <div class="w-full px-1 md:px-3">
                                <label
                                    class="block uppercase tracking-wide text-gray-700  font-bold mb-2 text-right"
                                    for="total"
                                >
                                    Total {{ useFormatNumber(totalPrice) }} (฿)</label>
                            </div>
                        </div>
                        <!--Footer-->
                        <div class="flex justify-end pt-2">
                            <button
                                type="button"
                                class="p-3 rounded-lg bg-red-400 hover:bg-red-600 text-white font-bold mr-2"
                                @click="clear"
                            >
                                Clear
                            </button>
                            <button
                                type="button"
                                class="p-3 rounded-lg bg-green-400 hover:bg-green-600 text-white font-bold mr-2"
                                @click="onSubmit"
                            >
                                Add
                            </button>
                        </div>
                    <!-- </form> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/services/auth';
import { ref, computed } from "vue";
import { useFormatNumber, useNumberTwoDecimal } from '@/js/format';
import { useStore } from 'vuex';
import { v4 as uuidv4 } from 'uuid';

export default {
    name:"ProductModal",
    props : {
        onClose:{
            type: Function
        }
    },
    setup(props){
        const input_document_model_name = ref(null);
        const input_serial = ref(null);
        const form = ref({
            uuid:uuidv4(),
            model_id:"",
            barcode:"",
            document_model_name:"",
            warehouse_shelf_id:parseFloat(process.env.VUE_APP_MANUAL_ORDER_WAREHOUSE_SHELF_ID),
            serial:"",
            ppe:0,
            quantity:1,
            discount_baht:0,
            discount_percent:0,
            total:0,
            is_edit:false,
        });
        const store = useStore();

        const clear = () => {
            form.value = {
                uuid:uuidv4(),
                model_id:"",
                barcode:"",
                document_model_name:"",
                warehouse_shelf_id:parseFloat(process.env.VUE_APP_MANUAL_ORDER_WAREHOUSE_SHELF_ID),
                serial:"",
                ppe:0,
                quantity:1,
                discount_baht:0,
                discount_percent:0,
                total:0,
                is_edit:false,
            }
        }
        const totalPrice = computed(() => {
            return (form.value.ppe - form.value.discount_baht) * form.value.quantity;
        });
        const discountPercent = computed(() => {
            return useNumberTwoDecimal((form.value.discount_baht * 100) / form.value.ppe);
        });
        const onEnterBarcode = () => {
            api.get(`api/barcode/${btoa(form.value.barcode)}`)
                .then(response => {
                    form.value.model_id = response.data.id
                    form.value.document_model_name = response.data.name
                    input_serial.value.focus();
                }).catch( () => {
                    form.value.model_id = ""
                    form.value.document_model_name = ""
                    input_document_model_name.value.focus();
                })
        }
        const onSubmit = () => {
            form.value = {...form.value, discount_percent: discountPercent, total: totalPrice.value};
            store.commit('manualOrder/setLineitem', form.value)
            props.onClose();
        }
        return {
            input_document_model_name, input_serial, form, clear, totalPrice, useFormatNumber, onEnterBarcode, onSubmit
        }
    }
}
</script>