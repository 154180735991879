import moment from 'moment'

export function useFormatNumber(value){
    let val = (value/1).toFixed(2).replace(',', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}
export function useFormatDate(value){
    if(value){
        return moment(String(value)).format('DD MMM YYYY')
    }
}
export function useNumberTwoDecimal(number){
    return Math.round((parseFloat(number) + Number.EPSILON) * 100) / 100;
}